import {
  AnimalSortOrderType,
  AnimalSpecieCategoryId,
  Dashboard,
} from '@colensobbdo/shelter-management-frontend-integration';

export type ViewByType = Dashboard.View;

export const FilterByAll = -1;
export type FilterByType = AnimalSpecieCategoryId | typeof FilterByAll;

export type DashboardState = {
  animalsListSortOrder?: AnimalSortOrderType;
  animalsListInMyCare?: boolean;
  viewBy?: ViewByType;
  filterBy?: FilterByType;
  pageloadModal: PageloadModal | null;
};

export enum PageloadModal {
  DECEASED_ARCHIVE_THANKYOU = 1,
}

export const SET_ANIMALS_LIST_SORT_ORDER = 'SET_ANIMALS_LIST_SORT_ORDER';
export const SET_ANIMALS_LIST_IN_MY_CARE = 'SET_ANIMALS_LIST_IN_MY_CARE';
export const SET_VIEW_BY = 'SET_VIEW_BY';
export const SET_FILTER_BY = 'SET_FILTER_BY';
export const SET_PAGELOAD_MODAL = 'SET_PAGELOAD_MODAL';

interface setAnimalsListSortOrderAction {
  type: typeof SET_ANIMALS_LIST_SORT_ORDER;
  payload: AnimalSortOrderType;
}

interface setAnimalsListInMyCareAction {
  type: typeof SET_ANIMALS_LIST_IN_MY_CARE;
  payload: boolean;
}

interface setViewBy {
  type: typeof SET_VIEW_BY;
  payload: ViewByType;
}

interface setFilterBy {
  type: typeof SET_FILTER_BY;
  payload: FilterByType;
}

interface setPageloadModal {
  type: typeof SET_PAGELOAD_MODAL;
  payload: PageloadModal | null;
}

export type DashboardActionTypes =
  | setAnimalsListSortOrderAction
  | setAnimalsListInMyCareAction
  | setPageloadModal
  | setViewBy
  | setFilterBy;
