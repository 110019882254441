import {
  AnimalApplication,
  IAdoptionApplication,
  IPagination,
  ShelterApplication,
} from '@colensobbdo/shelter-management-frontend-integration';

export const APPLICATION_LIST_CACHE_TIME = 10 * 60 * 1000; // 10 min
export const APPLICATION_LIST_PAGE_SIZE = 10;

export enum ApiDataType {
  ANIMAL,
  SHELTER,
}

export interface ApplicationListState {
  rows: IAdoptionApplication[];
  pagination: IPagination | null;
  isLoading: boolean;
  error: string | null;
}

export interface ApplicationState {
  animalName: string | null;
  list: ApplicationListState;
}

export type ApplicationResponse = AnimalApplication.SearchResponse | ShelterApplication.Response;

export const APPLICATION_LIST_DATA_CLEAR = 'APPLICATION_LIST_DATA_CLEAR';
export const APPLICATION_LIST_LOAD_START = 'APPLICATION_LIST_LOAD_START';
export const APPLICATION_LIST_DATA_LOAD_END = 'APPLICATION_LIST_DATA_LOAD_END';
export const APPLICATION_LIST_DATA_PAGE_LOADED = 'APPLICATION_LIST_DATA_PAGE_LOADED';

interface ApplicationListLoadStart {
  type: typeof APPLICATION_LIST_LOAD_START;
}

interface ApplicationListDataClearAction {
  type: typeof APPLICATION_LIST_DATA_CLEAR;
}

interface ApplicationListDataLoadEndAction {
  type: typeof APPLICATION_LIST_DATA_LOAD_END;
  payload: {
    api: ApplicationResponse;
    type: ApiDataType;
  };
}

interface ApplicationListDataPageLoadEndAction {
  type: typeof APPLICATION_LIST_DATA_PAGE_LOADED;
  payload: {
    api: ApplicationResponse;
    type: ApiDataType;
  };
}

export type ApplicationActionTypes =
  | ApplicationListDataClearAction
  | ApplicationListDataPageLoadEndAction
  | ApplicationListLoadStart
  | ApplicationListDataLoadEndAction;
