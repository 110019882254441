import {
  IRegistrationState,
  RegistrationActionTypes,
  SET_REGISTRATION_ALL_UPDATES_PREFERENCE,
  SET_REGISTRATION_ANALYTICS_PREFERENCE,
  SET_REGISTRATION_FLAGS,
  SET_REGISTRATION_MARKETING_PREFERENCE,
  SET_REGISTRATION_SHELTER_DETAILS,
  SET_REGISTRATION_STEP,
  SET_REGISTRATION_USER_DETAILS,
} from './types';

const initialState: IRegistrationState = {
  step: 'CreatePersonStep',
  isCreateNewShelterFlow: false,
  isInviteUserFlow: false,
  isNewUser: false,
  isVolunteer: false,
  isFoster: false,
  isVet: false,
  user: null,
  shelter: null,
  optInMarketingMyHooman: false,
  optInMarketingMarsAndAffiliates: false,
  optInForAnalytics: false,
  optInAllUpdates: false,
};

export function registrationReducer(state = initialState, action: RegistrationActionTypes): IRegistrationState {
  switch (action.type) {
    case SET_REGISTRATION_STEP:
      if (action.payload === 'CreatePersonStep') {
        return {
          ...initialState,
        };
      }

      return {
        ...state,
        step: action.payload,
      };
    case SET_REGISTRATION_FLAGS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_REGISTRATION_USER_DETAILS:
      return {
        ...state,
        user: action.payload,
      };
    case SET_REGISTRATION_SHELTER_DETAILS:
      return {
        ...state,
        shelter: action.payload,
      };
    case SET_REGISTRATION_ALL_UPDATES_PREFERENCE:
      return {
        ...state,
        optInAllUpdates: action.payload,
      };
    case SET_REGISTRATION_MARKETING_PREFERENCE:
      return {
        ...state,
        optInMarketingMyHooman: action.payload.optInMarketingMyHooman,
        optInMarketingMarsAndAffiliates: action.payload.optInMarketingMarsAndAffiliates,
      };

    case SET_REGISTRATION_ANALYTICS_PREFERENCE:
      return {
        ...state,
        optInForAnalytics: action.payload,
      };
    default:
      return state;
  }
}
