// state

export type SearchType = 'generic' | 'animals' | 'community' | 'applications';

export interface RecentSearch {
  readonly text: string;
  readonly type: SearchType;
  readonly count: number;
  readonly createdOn: string;
}

export type SearchState = {
  recentSearches: RecentSearch[];
};

// action

export const SEARCH_STORE = 'SEARCH_STORE';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';

export type SearchStoreLastActionPayload = Omit<RecentSearch, 'createdOn'>;

interface SearchStoreLastAction {
  type: typeof SEARCH_STORE;
  payload: SearchStoreLastActionPayload;
}

interface SearchClearHistoryAction {
  type: typeof SEARCH_CLEAR;
}

export type SearchActionTypes = SearchStoreLastAction | SearchClearHistoryAction;
