import { AnimalSearch, IPagination } from '@colensobbdo/shelter-management-frontend-integration';

export const ANIMAL_LIST_CACHE_TIME = 10 * 60 * 1000; // 10 min
export const ANIMAL_LIST_PAGE_SIZE = 10;

export interface AnimalListState {
  rows: AnimalSearch.ResponseData['animals'];
  applicationByStatus: AnimalSearch.ResponseData['applications'];
  isLoading: boolean;
  isPageLoading: boolean;
  loadedOn: number;
  expiresOn: number;
  pagination: IPagination | null;
  filters: AnimalSearch.Filters;
  error: string | null;
  archiveDate?: string | null;
}

export interface AnimalsState {
  invalidationDate: number;
  list: AnimalListState;
  isArchiveMode: boolean;
}

export const ANIMAL_LIST_DATA_CLEAR = 'ANIMAL_LIST_DATA_CLEAR';
export const ANIMAL_LIST_INVALIDATE_ACTION = 'ANIMAL_LIST_INVALIDATE_ACTION';
export const ANIMAL_LIST_DATA_LOAD_START = 'ANIMAL_LIST_DATA_LOAD_START';
export const ANIMAL_LIST_DATA_LOAD_END = 'ANIMAL_LIST_DATA_LOAD_END';
export const ANIMAL_LIST_DATA_PAGE_LOAD_START = 'ANIMAL_LIST_DATA_PAGE_LOAD_START';
export const ANIMAL_LIST_DATA_PAGE_LOADED = 'ANIMAL_LIST_DATA_PAGE_LOADED';
export const ANIMAL_LIST_SET_LOADING_ONLY = 'ANIMAL_LIST_SET_LOADING_ONLY';
export const ANIMAL_LIST_SET_ARCHIVE_MODE = 'ANIMAL_LIST_SET_ARCHIVE_MODE';

interface AnimalListDataClearAction {
  type: typeof ANIMAL_LIST_DATA_CLEAR;
}

interface AnimalListSetArchiveModeAction {
  type: typeof ANIMAL_LIST_SET_ARCHIVE_MODE;
  payload: boolean;
}

interface TasksListInvalidateAction {
  type: typeof ANIMAL_LIST_INVALIDATE_ACTION;
}

interface AnimalListDataLoadStartAction {
  type: typeof ANIMAL_LIST_DATA_LOAD_START;
  payload: AnimalSearch.Request;
}

interface AnimalListDataLoadEndAction {
  type: typeof ANIMAL_LIST_DATA_LOAD_END;
  payload: AnimalSearch.Response;
}

interface AnimalListDataPageLoadStartAction {
  type: typeof ANIMAL_LIST_DATA_PAGE_LOAD_START;
}

interface AnimalListDataPageLoadEndAction {
  type: typeof ANIMAL_LIST_DATA_PAGE_LOADED;
  payload: AnimalSearch.Response;
}

interface AnimalListSetLoadingOnly {
  type: typeof ANIMAL_LIST_SET_LOADING_ONLY;
  payload: boolean;
}

export type AnimalsActionTypes =
  | AnimalListDataClearAction
  | TasksListInvalidateAction
  | AnimalListDataLoadStartAction
  | AnimalListDataLoadEndAction
  | AnimalListDataPageLoadStartAction
  | AnimalListDataPageLoadEndAction
  | AnimalListSetArchiveModeAction
  | AnimalListSetLoadingOnly;
