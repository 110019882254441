import {
  AnimalSpecieCategoryId,
  IUserGroupLookup,
  getFullNameFormatted,
  getInitialsFormatted,
} from '@colensobbdo/shelter-management-frontend-integration';
import _map from 'lodash/map';

import { RootState } from '../store';

export const getPersonId = (state: RootState): number | null => state.person.personId;
export const getShelterPersonId = (state: RootState): number | null => state.person.shelterPersonId;
export const getCountryId = (state: RootState): number | null => state.person.countryId;
export const getEmail = (state: RootState): string | null => state.person.email;
export const getFirstName = (state: RootState): string | null => state.person.firstName;
export const getLastName = (state: RootState): string | null => state.person.lastName;
export const getFullName = (state: RootState): string => getFullNameFormatted(getFirstName(state), getLastName(state));
export const getInitials = (state: RootState): string => getInitialsFormatted(getFirstName(state), getLastName(state));
export const getAvatarUrl = (state: RootState): string | null => state.person.photoUrl;
export const getUserPhone = (state: RootState): string | null => state.person.phone;
export const getAccountIsActive = (state: RootState): boolean => state.person.isActive;
export const getUserLanguageId = (state: RootState): number | null => state.person.languageId;
export const getUserGroups = (state: RootState): IUserGroupLookup[] | null => state.person.userGroups;
export const getUserGroupLabels = (state: RootState): string[] => _map(getUserGroups(state), (group) => group.name);
export const getFosterEnabledSpecies = (state: RootState): AnimalSpecieCategoryId[] =>
  state.person.fosterEnabledSpecies;
