import { AnimalSearch } from '@colensobbdo/shelter-management-frontend-integration';

import {
  ANIMAL_LIST_DATA_CLEAR,
  ANIMAL_LIST_DATA_LOAD_END,
  ANIMAL_LIST_DATA_LOAD_START,
  ANIMAL_LIST_DATA_PAGE_LOADED,
  ANIMAL_LIST_DATA_PAGE_LOAD_START,
  ANIMAL_LIST_INVALIDATE_ACTION,
  ANIMAL_LIST_SET_ARCHIVE_MODE,
  ANIMAL_LIST_SET_LOADING_ONLY,
  AnimalsActionTypes,
} from './types';

export function animalListDataClear(): AnimalsActionTypes {
  return {
    type: ANIMAL_LIST_DATA_CLEAR,
  };
}

export function animalListInvalidate(): AnimalsActionTypes {
  return {
    type: ANIMAL_LIST_INVALIDATE_ACTION,
  };
}

export function animalListDataStartLoading(request: AnimalSearch.Request): AnimalsActionTypes {
  return {
    type: ANIMAL_LIST_DATA_LOAD_START,
    payload: request,
  };
}

export function animalListDataLoaded(response: AnimalSearch.Response): AnimalsActionTypes {
  return {
    type: ANIMAL_LIST_DATA_LOAD_END,
    payload: response,
  };
}

export function animalListPageLoadStart(): AnimalsActionTypes {
  return {
    type: ANIMAL_LIST_DATA_PAGE_LOAD_START,
  };
}

export function animalListSetLoadingOnly(active: boolean): AnimalsActionTypes {
  return {
    type: ANIMAL_LIST_SET_LOADING_ONLY,
    payload: active,
  };
}

export function animalListPageDataLoaded(response: AnimalSearch.Response): AnimalsActionTypes {
  return {
    type: ANIMAL_LIST_DATA_PAGE_LOADED,
    payload: response,
  };
}

export function animalListSetArchiveMode(status: boolean): AnimalsActionTypes {
  return {
    type: ANIMAL_LIST_SET_ARCHIVE_MODE,
    payload: status,
  };
}
