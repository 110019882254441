import {
  CreateShelter,
  RegistrationStatusType,
  ShelterDetails,
  ShelterIdType,
  ShelterSwitch,
} from '@colensobbdo/shelter-management-frontend-integration';
import { ThunkAction } from '@reduxjs/toolkit';
import routes from 'configs/routes';
import _isEqual from 'lodash/isEqual';
import { Action, Dispatch } from 'redux';
import { thunkSaveTokens } from 'store/auth/thunks';
import { personSetActive } from 'store/person/actions';
import { thunkUpdatePersonDetails } from 'store/person/thunks';
import { setRegistrationStep, updateRegistrationFlags } from 'store/registration/actions';
import { searchClearHistory } from 'store/search/actions';
import { GetState, RootState } from 'store/store';
import { usergroupsListClear } from 'store/usergroups/actions';

import { shelterDataClear, shelterDataUpdate } from './actions';
import { getShelterId } from './selectors';
import { ShelterState } from './types';

export const thunkUpdateShelterEnabledSpecies =
  (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const shelterId = getShelterId(state);

    const response = await ShelterDetails.getSpecies({ shelterId });

    if (!response.success) return;

    const enabledSpecies = response.data.species
      .filter((species) => species.status === 1)
      .map((species) => species.animalSpeciesCategory);

    if (!_isEqual(enabledSpecies, state.shelter.enabledSpecies)) dispatch(shelterDataUpdate({ enabledSpecies }));
  };

export const thunkUpdateShelterDetails =
  (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const shelterId = getShelterId(state);

    const response = await ShelterDetails.call({ shelterId });

    if (!response.success) return;

    const shelterDetails: Partial<ShelterState> = {
      photoUrl: response.data.logo,
      email: response.data.email,
      enabledSpecies: response.data.capacities
        .filter((species) => species.status === 1)
        .map((species) => species.animalSpeciesCategory),
      mode: response.data.mode,
    };

    dispatch(shelterDataUpdate(shelterDetails));
  };

export const thunkUpdateShelterDetailsDuringRegistration =
  (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const shelterId = getShelterId(state);
    const response = await CreateShelter.getShelterInfo({
      shelterId,
    });

    if (!response.success) return;

    const newShelterDetails: Partial<ShelterState> = {
      name: response.data.name,
      photoUrl: response.data.logoUrl,
      shelterExternalId: response.data.externalId,
    };

    dispatch(shelterDataUpdate(newShelterDetails));
  };

export const thunkSwitchShelter =
  (
    shelterId: ShelterIdType,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    history: any,
    redirectUrl = routes.pages.root,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch: Dispatch) => {
    const response = await ShelterSwitch.switchShelter({
      shelterId,
    });

    if (!response.success) return response;

    dispatch<any>(thunkSaveTokens(response.data.tokens));

    // delete shelter specific information
    dispatch<any>(shelterDataClear());
    dispatch<any>(
      shelterDataUpdate({
        shelterId,
      }),
    );
    dispatch<any>(usergroupsListClear());
    dispatch<any>(searchClearHistory());

    switch (response.data.registrationStatus) {
      case RegistrationStatusType.InvitationInProgress: {
        //restore invited user registration status
        await dispatch<any>(thunkUpdatePersonDetails());

        const newFlags = {
          isInviteUserFlow: true,
          isNewUser: false,
          isVolunteer: false,
          isFoster: false,
          isVet: false,
        };

        //update registration flags
        await dispatch<any>(updateRegistrationFlags(newFlags));

        history.push(routes.pages.registration.root);
        break;
      }
      case RegistrationStatusType.RegistrationInProgress:
        //restore registration status
        dispatch(setRegistrationStep('UpdatePersonStep'));
        history.replace(routes.pages.registration.root);
        break;
      default:
      case RegistrationStatusType.Registered:
        //re-route to dashboard
        dispatch(personSetActive());

        await dispatch<any>(thunkUpdatePersonDetails());
        await dispatch<any>(thunkUpdateShelterDetails());

        // forcing reload
        // history.replace(redirectUrl);
        window.location.href = redirectUrl;
        break;
    }

    return response;
  };
