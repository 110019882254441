import { Button, Header as HeaderComponent, Icons, Logo } from '@colensobbdo/shelter-management-frontend';
import React from 'react';
import { useHistory } from 'react-router-dom';

export type HeaderProps = {
  block?: boolean;
  center?: boolean;
  children?: React.ReactNode;
  enableBackButton?: boolean;
  menu?: 'animals' | 'animal-profile' | 'community' | 'community-profile' | 'default' | 'litter' | React.ReactNode;
  suffix?: React.ReactNode;
  size?: 'large' | 'full';
  onPressBack?: () => void;
  menuOpen?: boolean;
  setMenuOpen?: (toggle: boolean) => void;
};

const { ChevronLeftIcon, CrossLargeIcon, SettingsVerticalIcon } = Icons;

const Header: React.FC<HeaderProps> = (props) => {
  const history = useHistory();
  const { menuOpen, setMenuOpen } = props;

  const _renderSuffix = () => {
    if (props.suffix) return props.suffix;

    if (props.menu) {
      return (
        <Button icon text onClick={() => setMenuOpen && setMenuOpen(true)}>
          {menuOpen ? <CrossLargeIcon /> : <SettingsVerticalIcon />}
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      <HeaderComponent
        {...props}
        affix={
          props.enableBackButton && (
            <Button onClick={() => (props.onPressBack ? props.onPressBack() : history.goBack())} icon text>
              <ChevronLeftIcon />
            </Button>
          )
        }
        suffix={_renderSuffix()}
      >
        {props.children ? props.children : <Logo />}
      </HeaderComponent>
    </>
  );
};

Header.defaultProps = {
  enableBackButton: false,
};

export default Header;
