import {
  IPagination,
  ITaskDetails,
  PaginationParams,
  TaskSearch,
} from '@colensobbdo/shelter-management-frontend-integration';

export type PanelType = 'panel-animal-tasks' | 'panel-person-tasks' | 'panel-shelter-tasks';

export const TASKS_CACHE_TIME = 5 * 60 * 1000; // 5 min
export const TASKS_PAGE_SIZE = 15;

type AnimalTaskListFilters = Omit<TaskSearch.ByAnimalRequest, keyof TaskSearch.Filters | keyof PaginationParams>;
type PeopleTaskListFilters = Omit<TaskSearch.ByPersonRequest, keyof TaskSearch.Filters | keyof PaginationParams>;
type ShelterTaskListFilters = Omit<TaskSearch.ByShelterRequest, keyof TaskSearch.Filters | keyof PaginationParams>;

/**
 * this is a type that provides all the possible search parameters
 * for the 3 task list api methods
 * without the pagination params.
 * We use this type to detect whenever the search change in order to
 * decide whenever we need to invalidate (or not) the cached task list
 * @link {./reducers.ts}
 */
export type TaskListFilters = TaskSearch.Filters &
  Partial<AnimalTaskListFilters> &
  Partial<PeopleTaskListFilters> &
  Partial<ShelterTaskListFilters>;

export type TaskSearchApiRequest = TaskListFilters & PaginationParams;
export type TaskSearchApiResponse =
  | TaskSearch.ByAnimalResponse
  | TaskSearch.ByPersonResponse
  | TaskSearch.ByShelterResponse;

export interface TaskListState {
  rows: ITaskDetails[];
  isLoading: boolean;
  loadedOn: number;
  expiresOn: number;
  pagination: IPagination | null;
  filters: TaskListFilters;
  error: string | null;
}

export interface TasksState {
  invalidationDate: number;
  animal: TaskListState;
  person: TaskListState;
  shelter: TaskListState;
}

export type TaskListType = 'animal' | 'person' | 'shelter';

export const TASKS_DATA_CLEAR = 'TASKS_DATA_CLEAR';
export const TASKS_LIST_INVALIDATE_ACTION = 'TASKS_LIST_INVALIDATE_ACTION';
export const TASKS_LIST_DATA_LOAD_START = 'TASKS_LIST_DATA_LOAD_START';
export const TASKS_LIST_DATA_LOAD_END = 'TASKS_LIST_DATA_LOAD_END';
export const TASKS_LIST_DATA_PAGE_LOAD_START = 'TASKS_LIST_DATA_PAGE_LOAD_START';
export const TASKS_LIST_DATA_PAGE_LOADED = 'TASKS_LIST_DATA_PAGE_LOADED';

interface TasksDataClearAction {
  type: typeof TASKS_DATA_CLEAR;
}

interface TasksListInvalidateAction {
  type: typeof TASKS_LIST_INVALIDATE_ACTION;
}

interface TaskListDataLoadStartAction {
  type: typeof TASKS_LIST_DATA_LOAD_START;
  payload: {
    type: TaskListType;
    filters: TaskSearchApiRequest;
  };
}

interface TaskListDataLoadEndAction {
  type: typeof TASKS_LIST_DATA_LOAD_END;
  payload: {
    type: TaskListType;
    response: TaskSearchApiResponse;
  };
}

interface TaskListDataPageLoadStartAction {
  type: typeof TASKS_LIST_DATA_PAGE_LOAD_START;
  payload: {
    type: TaskListType;
  };
}

interface TaskListDataPageLoadEndAction {
  type: typeof TASKS_LIST_DATA_PAGE_LOADED;
  payload: {
    type: TaskListType;
    response: TaskSearchApiResponse;
  };
}

export type TasksActionTypes =
  | TasksDataClearAction
  | TasksListInvalidateAction
  | TaskListDataLoadStartAction
  | TaskListDataLoadEndAction
  | TaskListDataPageLoadStartAction
  | TaskListDataPageLoadEndAction;
