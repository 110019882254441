import 'services/ConsoleFilter';
import 'normalize.css';

import './public-path.ts';
import './configs';
import './scss/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
