import { Container, Heading, Spacer, Text } from '@colensobbdo/shelter-management-frontend';
import routes from 'configs/routes';
import React from 'react';
import { Link } from 'react-router-dom';

export const WhatIsMyHoomanContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          What is MyHooman?
        </Heading>
        <Text>
          MyHooman is a free, cloud-based shelter management software, made to reduce your admin and connect more pets
          with their forever hoomans.
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const WhatDoesMyHoomanCostContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Does MyHooman cost?
        </Heading>
        <Text>MyHooman is completely free.</Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const WhatHappensToDataContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          What happens to saved data?
        </Heading>
        <Text>
          To find out more about our terms of service, including privacy and data considerations, check out our terms
          and conditions here:{' '}
          {
            <Link to={routes.pages.termsAndConditionsPage}>
              https://shelter.myhooman.com{routes.pages.termsAndConditionsPage}
            </Link>
          }
          {<br />}And our privacy policy here:{' '}
          {
            <a target="_blank" rel="noreferrer nofollow" href="https://www.mars.com/privacy-policy-australia">
              https://www.mars.com/privacy-policy-australia
            </a>
          }
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const WhoCanAccessContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Who from my shelter can sniff through our account?
        </Heading>
        <Text>
          If you work in a team of hoomans, with fosters and volunteers as well as your core staff, you can invite them
          to your MyHooman shelter with custom permissions levels.
        </Text>
        <Text>MyHooman makes it easy to manage the permissions for each individual user group:</Text>
        <Text>
          Level 1: Shelter Master – When you create your shelter on MyHooman, you automatically become the Shelter
          Master with the highest level of permissions. You can edit and view anything, plus, if required, you have the
          permission to delete the shelter from MyHooman.
        </Text>
        <Text>
          Level 2: Shelter Admin – Shelter Admin hoomans have the same level of permissions as Shelter Master, or Level
          1, but they cannot delete the shelter.
        </Text>
        <Text>
          Level 3: Staff – Hooman staff can view and edit doggo profiles and the shelter community, but cannot view or
          edit adoption applications or the shelter&apos;s settings.
        </Text>
        <Text>
          Level 4: Volunteers – Volunteering hoomans can only edit Tasks and their own settings. They can view most
          aspects of the shelter except for adoption applications.
        </Text>
        <Text>
          At MyHooman, we&apos;re always working to improve our permissions functionality, so feel free to woof out and
          give us any feedback.
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const RegisterContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Registering a shelter
        </Heading>
        <Text>
          {<strong>Who should register a shelter?</strong>}
          {<br />}
          Only one hooman needs to register your shelter – normally, this would be the leader of your pack, like a
          Shelter or Rescue Director, or Lead Manager.
        </Text>
        <Text>
          If you&apos;re a Staff, Volunteer or Foster Hooman, get your manager to register your shelter first, then they
          can invite you to join!
        </Text>
        <Text>
          {<strong>What is needed to set up the shelter?</strong>}
          {<br />}
          All the simple stuff – like the name of the shelter, any contact details and the type of animals the shelter
          cares for.
        </Text>
        <Text>
          Once entered and confirmed through an activation link, the shelter is set up! From there, the manager can
          start adding any pet data, plus invite more hoomans they work with – like Fosters and Staff.
        </Text>
        <Text>
          MyHooman is free, cloud-based shelter management software, made to reduce your admin and connect more doggos
          with their forever hoomans.
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const ActivationContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Activation links
        </Heading>
        <Text>
          {<strong>What is an activation link?</strong>}
          {<br />}
          On creating a shelter profile, we&apos;ll quickly be in touch with an &apos;activation email&apos;.
        </Text>
        <Text>
          It&apos;s an exciting (and important) little step to let us both know you&apos;ve entered the right email
          address! Once confirmed, you&apos;ll be able to sign in wherever you want from any device using that email
          address and your super secret password.
        </Text>
        <Text>
          {
            <strong>
              I&apos;ve registered my shelter for MyHooman but I didn&apos;t receive my activation link email
            </strong>
          }
          {<br />}
          Firstly, welcome hooman! Thanks for joining MyHooman.{<br />}Try entering your email at this URL{' '}
          {
            <Link to={routes.pages.resendActivationEmail}>
              https://shelter.myhooman.com{routes.pages.resendActivationEmail}
            </Link>
          }
          {<br />}If that doesn&apos;t work, give us a call on 0800 MYHOOMAN (0800 69 466626).
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const InvitingShelterContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Inviting people to your shelter
        </Heading>
        <Text>
          {<strong>I want to add someone to my community but I don&apos;t have their phone number</strong>}
          {<br />}
          We&apos;re working to update the mandatory fields on shelter community profiles. In the meantime, please pop
          in a landline or temporary number. The MyHooman team will inform users of updates as we release them.
        </Text>
        <Text>
          {<strong>I want to add someone to my community but they already have a MyHooman account</strong>}
          {<br />}
          Furtastic!&apos; MyHooman has been designed so people can use one login to access multiple shelters. This is
          perfect for generous fosters or volunteers. Users who have been invited to more than one shelter can switch
          between shelters in their settings.
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const CustomiseShelterContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Customising your shelter
        </Heading>
        <Text>
          {<strong>Can I customise MyHooman?</strong>}
          {<br />}
          Yes! Certain parts of MyHooman are customisable. Your community permissions levels, adoption communications
          and applications are just a few of the functions you can customise to suit your shelter.
        </Text>
        <Text>
          Feel free to contact the MyHooman support hoomans for a demonstration of current or future functionality to
          see what&apos;s coming soon.
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const AddOrDeletePetContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Add or delete a pet
        </Heading>
        <Text>
          {<strong>What animals can I add to my shelter?</strong>}
          {<br />}
          MyHooman allows you to add both cats and doggos to your shelter, and soon we hope to add other four and
          two-leggers like farm animals and other small furry friends.
        </Text>
        <Text>
          {<strong>How do I add an animal to my shelter?</strong>}
          {<br />}
          It&apos;s woofin&apos; easy! Just use the + action menu to add an animal pal. Here, you&apos;ll also be able
          to create a litter at the same time, if you need.
        </Text>
        <Text>
          {<strong>How do I delete an animal from my shelter?</strong>}
          {<br />}
          To delete an animal profile right meow, simply click the three dots in the top right corner of the profile and
          select &#8220;delete animal profile&#8221;.
        </Text>
        <Text>
          Deleted profiles will be completely removed from your database, and won&apos;t show up in any future
          reporting. Your shelter&apos;s &apos;pet archive&apos; will happen automatically. It&apos;s a list of animal
          pals that are no longer in your shelter – ones that have been adopted, transferred out or are deceased.
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const AnimalDataContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Animal data
        </Heading>
        <Text>
          {
            <strong>
              Can I store all the pet&apos;s medical and behavioural information as well as the basic details?
            </strong>
          }
          {<br />}
          Yes! Our comprehensive pet profiles make it easy to keep track of each animal pal&apos;s progress. You can
          also add notes and documents to each profile so everything is stored in one place. Fluffin&apos; great!
        </Text>
        <Text>
          {<strong>Can I add pets that have already been adopted for my records?</strong>}
          {<br />}
          Yes, you can! Simply add the animal to your shelter, then create a new adoption application using the
          &apos;start application&apos; feature on their profile. Add the original application details to the form and
          attach any relevant PDFs to the pet&apos;s profile under &apos;documents&apos;.
        </Text>
        <Text>
          {<strong>Can I import my existing data?</strong>}
          {<br />}
          Currently, there is no automatic way to import previous data to MyHooman. There is, however, a great friendly
          team of hoomans who can help you import existing data, so your reporting stays up to date. Just woof for help!
        </Text>
        <Text>
          {<strong>Can I export reports?</strong>}
          {<br />}
          You can export records based on all historical data you&apos;ve added to your shelter.
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const AnimalReadyContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Animals ready for adoption
        </Heading>
        <Text>
          {<strong>I&apos;m ready to receive adoption applications for a pet.</strong>}
          {<br />}
          When an animal pal is ready for adoption (and all the mandatory fields in their profile are complete), you can
          share their profile to MyHooman using the &apos;Publish button in the &apos;adoption profile&apos; selection.
        </Text>
        <Text>
          Adopters who visit your pet&apos;s profile on <a href="http://myhooman.com/">MyHooman.com</a> can apply to
          adopt them. Any applications will show up in your shelter&apos;s adoption applications&apos; section, and in
          the pet&apos;s profile.
        </Text>
        <Text>
          When a new fur pal comes to your shelter, you can start their adoption profile to be public right away. Just
          use the &apos;Start Adoption&apos; feature from the ••• menu on the top right of the pet&apos;s profile.
        </Text>
        <Text>
          {<strong>How do I share my animals that are ready to adopt to my Facebook and social media accounts?</strong>}
          {<br />}
          Once the critters at your shelter are ready to be introduced to the world, you can publish them to MyHooman.
          When they&apos;re &apos;live&apos;, you can use our built in share button. With just two taps, you&apos;ll be
          able to share any of your shelter&apos;s pet pals that are live on MyHooman to social media: Facebook and
          Twitter on desktop computers, and Instagram, Facebook Twitter when you&apos;re using MyHooman on your mobile
          device.
        </Text>
        <Text>
          {<strong>How do I remove an animal I have published to MyHooman?</strong>}
          {<br />}
          When a pet has been published and is &apos;live&apos; on MyHooman, you can remove their profile so potential
          adopters can&apos;t view them.
        </Text>
        <Text>
          At the bottom of the pet&apos;s profile, there is a &apos;withdraw profile&apos; button. Tap this to remove
          the animal from MyHooman. This will also mean you won&apos;t receive any new adoption applications for this
          animal pal via MyHooman.
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);

export const AdoptionApplicationsContent: React.FC = () => (
  <Spacer direction="vertical" size="large">
    <Container limitInternalWidth>
      <Spacer direction="vertical" size="large">
        <Heading level="h3" tag="h1">
          Adoption application
        </Heading>
        <Text>
          {<strong>How can I customise my adoption form?</strong>}
          {<br />}
          MyHooman allows you to pick and choose which questions are included in your adoption form from a pre-designed
          list. Simply go to the adoption application settings page and toggle on the questions you want included.
          Unfortunately, you aren&apos;t able to upload your own shelters adoption form or write your own questions.
          Using a pre-approved list of questions helps us adhere to international privacy laws and make sure all
          MyHooman users&apos; information is kept safe.
        </Text>
        <Text>
          We&apos;re fluffin&apos; excited to make these as customisable as possible for future adoptions, and
          we&apos;re always open to more feedback to make sure the process is perfect for all hoomans and pets.
          We&apos;ll be letting all existing MyHooman shelters know as soon as more customisation is available!
        </Text>
        <Text>
          {<strong>What if my shelter has its own adoption Terms & Conditions?</strong>}
          {<br />}
          Shelters can upload their own Terms and Conditions on the adoption application settings page. Once you&apos;ve
          done this, any adopter who submits an application via MyHooman will be asked to read and accept your
          shelters&apos; own Terms and Conditions.
        </Text>
        <Text>
          {<strong>I want to change an animal&apos;s adoption status</strong>}
          {<br />}
          You can change the status of an adoption application by selecting the status from the drop down menu when
          viewing the application. Make sure to hit &apos;save&apos;.
        </Text>
        <Text>
          Once there is at least one application with the status &apos;Approved&apos;, the animal will be automatically
          withdrawn from the public facing website. This makes sure the animal won’t receive any new applications from
          hoomans.
        </Text>
        <Text>
          {
            <strong>
              Someone has applied but they weren&apos;t quite right for the animal – can I save their application for
              later?
            </strong>
          }
          {<br />}
          If a pet and an adopter aren&apos;t quite right for each other, MyHooman&apos;s &apos;swap&apos; feature
          (found within the adopters submitted application form) allows you to swap out the animal once you&apos;ve
          found a better match. This is provided the adopter is interested in applying for the new animal. Doing this
          keeps all the adopters’ application details intact.
        </Text>
      </Spacer>
    </Container>
  </Spacer>
);
