import { CommunitySearch } from '@colensobbdo/shelter-management-frontend-integration';

import {
  COMMUNITY_LIST_DATA_CLEAR,
  COMMUNITY_LIST_DATA_LOAD_END,
  COMMUNITY_LIST_DATA_LOAD_START,
  COMMUNITY_LIST_DATA_PAGE_LOADED,
  COMMUNITY_LIST_DATA_PAGE_LOAD_START,
  COMMUNITY_LIST_INVALIDATE_ACTION,
  CommunityActionTypes,
  CommunityFilters,
} from './types';

export function communityListDataClear(): CommunityActionTypes {
  return {
    type: COMMUNITY_LIST_DATA_CLEAR,
  };
}

export function communityListInvalidate(): CommunityActionTypes {
  return {
    type: COMMUNITY_LIST_INVALIDATE_ACTION,
  };
}

export function communityListDataStartLoading(request: CommunityFilters): CommunityActionTypes {
  return {
    type: COMMUNITY_LIST_DATA_LOAD_START,
    payload: request,
  };
}

export function communityListDataLoaded(response: CommunitySearch.Response): CommunityActionTypes {
  return {
    type: COMMUNITY_LIST_DATA_LOAD_END,
    payload: response,
  };
}

export function communityListPageLoadStart(): CommunityActionTypes {
  return {
    type: COMMUNITY_LIST_DATA_PAGE_LOAD_START,
  };
}

export function communityListPageDataLoaded(response: CommunitySearch.Response): CommunityActionTypes {
  return {
    type: COMMUNITY_LIST_DATA_PAGE_LOADED,
    payload: response,
  };
}
