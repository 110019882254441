import { USER_GROUP_LIST_CLEAR, USER_GROUP_LIST_UPDATE, UserGroup, UserGroupActionTypes } from './types';

export function usergroupsListUpdate(data: UserGroup[]): UserGroupActionTypes {
  return {
    payload: data,
    type: USER_GROUP_LIST_UPDATE,
  };
}

export function usergroupsListClear(): UserGroupActionTypes {
  return {
    type: USER_GROUP_LIST_CLEAR,
  };
}
