import _get from 'lodash/get';
import _omit from 'lodash/omit';

import {
  TASKS_CACHE_TIME,
  TASKS_DATA_CLEAR,
  TASKS_LIST_DATA_LOAD_END,
  TASKS_LIST_DATA_LOAD_START,
  TASKS_LIST_DATA_PAGE_LOADED,
  TASKS_LIST_DATA_PAGE_LOAD_START,
  TASKS_LIST_INVALIDATE_ACTION,
  TasksActionTypes,
  TasksState,
} from './types';

const initialState: TasksState = {
  invalidationDate: 0,
  animal: {
    rows: [],
    isLoading: false,
    loadedOn: 0,
    expiresOn: 0,
    pagination: null,
    filters: {},
    error: null,
  },
  person: {
    rows: [],
    isLoading: false,
    loadedOn: 0,
    expiresOn: 0,
    pagination: null,
    filters: {},
    error: null,
  },
  shelter: {
    rows: [],
    isLoading: false,
    loadedOn: 0,
    expiresOn: 0,
    pagination: null,
    filters: {},
    error: null,
  },
};

export function tasksReducer(state = initialState, action: TasksActionTypes): TasksState {
  switch (action.type) {
    case TASKS_DATA_CLEAR:
      return {
        ...initialState,
      };
    case TASKS_LIST_INVALIDATE_ACTION:
      return {
        ...state,
        invalidationDate: new Date().getTime(),
      };
    case TASKS_LIST_DATA_LOAD_START:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          rows: [],
          isLoading: true,
          filters: _omit(action.payload.filters, ['page', 'pageSize']),
          error: null,
        },
      };
    case TASKS_LIST_DATA_LOAD_END:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          rows: _get(action, 'payload.response.data', []),
          isLoading: false,
          loadedOn: new Date().getTime(),
          expiresOn: new Date().getTime() + TASKS_CACHE_TIME,
          pagination: _get(action, 'payload.response.pagination', null),
          error: _get(action, 'payload.response.error.error', null),
        },
      };

    case TASKS_LIST_DATA_PAGE_LOAD_START:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          isLoading: true,
          error: null,
        },
      };

    case TASKS_LIST_DATA_PAGE_LOADED:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          rows: [...state[action.payload.type].rows, ..._get(action, 'payload.response.data', [])],
          isLoading: false,
          pagination: _get(action, 'payload.response.pagination', null),
          error: _get(action, 'payload.response.error.error', null),
        },
      };

    default:
      return state;
  }
}
