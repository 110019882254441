import { ScopeType } from '@colensobbdo/shelter-management-frontend-integration';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import withAuthentication from '../../hoc/withAuthentication';

interface IPrivateRouteProps extends RouteProps {
  component?: any;
  scope?: ScopeType;
}

const PrivateRoute = (props: IPrivateRouteProps): JSX.Element => {
  const { component: Component, scope, ...rest } = props;

  const WithAuthentication = withAuthentication<IPrivateRouteProps>(Component, scope);

  return <Route {...rest} render={(props) => <WithAuthentication {...props} />} />;
};

export default PrivateRoute;
