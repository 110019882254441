import {
  AnimalSpecieCategoryId,
  IShelterMode,
  getFullNameFormatted,
  getInitialsFormatted,
} from '@colensobbdo/shelter-management-frontend-integration';
import _words from 'lodash/words';

import { RootState } from '../store';

export const getShelterId = (state: RootState): number | null => state.shelter.shelterId;

export const getShelterExternalId = (state: RootState): string | null => state.shelter.shelterExternalId;

export const getShelterName = (state: RootState): string => getFullNameFormatted(state.shelter.name);

export const getShelterEmail = (state: RootState): string | null => state.shelter.email;

export const getShelterInitials = (state: RootState): string =>
  getInitialsFormatted(..._words(state.shelter.name || ''));

export const getShelterAvatarUrl = (state: RootState): string | null => state.shelter.photoUrl;

export const getShelterEnabledSpecies = (state: RootState): AnimalSpecieCategoryId[] =>
  state.shelter.enabledSpecies ? state.shelter.enabledSpecies : [];

export const getShelterEnabledSpeciesCount = (state: RootState): number =>
  state.shelter.enabledSpecies && state.shelter.enabledSpecies.length;

export const getShelterSpeciesIsEnabled =
  (species: AnimalSpecieCategoryId) =>
  (state: RootState): boolean =>
    state.shelter.enabledSpecies && state.shelter.enabledSpecies.includes(species);

export const getShelterIsDogOnly = (state: RootState): boolean =>
  getShelterEnabledSpeciesCount(state) === 1 && getShelterSpeciesIsEnabled(AnimalSpecieCategoryId.Dogs)(state);

export const getShelterIsCatOnly = (state: RootState): boolean =>
  getShelterEnabledSpeciesCount(state) === 1 && getShelterSpeciesIsEnabled(AnimalSpecieCategoryId.Cats)(state);

export const getIsRealShelter = (state: RootState): boolean => state.shelter.mode === IShelterMode.REAL;
