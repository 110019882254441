import { CommunityFoster, CreatePerson, Person } from '@colensobbdo/shelter-management-frontend-integration';
import { ThunkAction } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import { Action, Dispatch } from 'redux';
import { shelterDataUpdate } from 'store/shelter/actions';
import { ShelterState } from 'store/shelter/types';
import { GetState, RootState } from 'store/store';
import { usernameStoreLastUsed } from 'store/username/actions';

import { personDataUpdate } from './actions';
import { getPersonId } from './selectors';
import { PersonState } from './types';

export const thunkUpdatePersonFosterEnabledSpecies =
  (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const personId = getPersonId(state);

    if (!personId) return;

    const response = await CommunityFoster.call({ personId });

    if (!response.success) return;

    const fosterEnabledSpecies = response.data.capacity
      .filter((species) => species.status === 1)
      .map((species) => species.animalSpeciesCategory);

    if (!_isEqual(fosterEnabledSpecies, state.person.fosterEnabledSpecies))
      dispatch(personDataUpdate({ fosterEnabledSpecies }));
  };
export const thunkUpdatePersonDetails =
  (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
    const response = await Person.call();

    if (response.success) {
      const newPersonDetails: Partial<PersonState> = {
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        phone: response.data.phone,
        photoUrl: response.data.photoUrl,
        languageId: response.data.languageId,
        userGroups: response.data.userGroups,
      };

      const newShelterDetails: Partial<ShelterState> = {
        name: response.data.shelterName,
      };

      dispatch(personDataUpdate(newPersonDetails));
      dispatch(shelterDataUpdate(newShelterDetails));

      if (newPersonDetails.email) {
        dispatch(usernameStoreLastUsed(newPersonDetails.email));
      }

      return newShelterDetails;
    }
  };

export const thunkUpdatePersonDetailsDuringRegistration =
  (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
    const response = await CreatePerson.getPersonInfo();

    if (response.success) {
      dispatch(personDataUpdate(response.data));
    }
  };
