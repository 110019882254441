import { configuration as integration } from '@colensobbdo/shelter-management-frontend-integration';

// Development  = http://localhost:9000/
// Staging      = https://ped-sms.dev.levo.com.au/
// UAT          = https://uat.myhooman.com/
// Production   = https://shelter.myhooman.com/
export type EnvironmentType = 'Development' | 'Staging' | 'UAT' | 'Production';

interface IConfig {
  environment: EnvironmentType;
  assetPath: string;
  apiEndpoint: string;
  breedDetectionApiKey: string;
  googleMapsPlacesApiKey: string;
  timezone: string;
  locale: string;
  version: string;
}

interface CustomNavigator extends Navigator {
  userLanguage?: string;
}

interface CustomGlobal extends NodeJS.Global {
  config?: IConfig;
  navigator: CustomNavigator;
}

// Global config BE index.html:
// <script>
//   window.config = {
//     environment: '',
//     assetPath: '',
//     apiEndpoint: '',
//     breedDetectionApiKey: '',
//     googleMapsPlacesApiKey: '',
//   };
// </script>
const GLOBAL = global as CustomGlobal;

const getAssetPath = () => {
  const path = GLOBAL.config?.assetPath;
  if (!path) return null;
  return path.substring(-1) === '/' ? path : `${path}/`;
};

const getLocale = () => GLOBAL.navigator.userLanguage || GLOBAL.navigator.language || undefined;

const getTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    return null;
  }
};

const CONFIG: IConfig = {
  environment: GLOBAL.config?.environment || 'Development',
  assetPath: getAssetPath() || '/',
  apiEndpoint: GLOBAL.config?.apiEndpoint || process.env.API_BASE_URL || '/',
  breedDetectionApiKey: GLOBAL.config?.breedDetectionApiKey || process.env.BREED_DETECTION_API_KEY || '',
  googleMapsPlacesApiKey: GLOBAL.config?.googleMapsPlacesApiKey || process.env.GMAPS_PLACES_API_KEY || '',
  version: process.env.APP_VERSION || '',
  locale: getLocale() || 'en',
  timezone: getTimezone() || 'Pacific/Auckland',
};

const { apiEndpoint, timezone, locale, environment } = CONFIG;

integration.set({ apiEndpoint, timezone, locale });

export const IS_DEV = environment === 'Development' || environment === 'Staging';
export const IS_UAT = environment === 'UAT';

if (IS_DEV || IS_UAT) console.log('🐶', { ...CONFIG, integration: integration.get() });

export * from './support';

export default CONFIG;
