import { Scope } from '@colensobbdo/shelter-management-frontend-integration';
import loadable from '@loadable/component';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { hasAccessLevel2, isAuthenticated } from 'store/auth/selectors';
import { getPersonId } from 'store/person/selectors';
import { thunkUpdatePersonDetails } from 'store/person/thunks';
import { getShelterId } from 'store/shelter/selectors';
import { thunkUpdateShelterDetails } from 'store/shelter/thunks';

import PrivateRoute from './components/PrivateRoute';
import RouteSwitch from './components/RouteSwitch';
import { IS_DEV } from './configs';
import routes from './configs/routes';
import { Faqs } from './pages/FaqPage/modules/Faqs';
import { pushGtmEvent } from './services/GTM';

const AccountSettingsPage = loadable(() => import('./pages/AccountSettingsPage'));
const ActivityLogPage = loadable(() => import('./pages/ActivityLogPage'));
const AnimalApplicationsPage = loadable(() => import('./pages/AnimalApplicationsPage'));
const AnimalApplicationPage = loadable(() => import('./pages/AnimalApplicationPage'));
const AnimalForcedApplicationPage = loadable(() => import('./pages/AnimalForcedApplicationPage'));
const AnimalLitterPage = loadable(() => import('./pages/AnimalLitterPage'));
const AnimalsPage = loadable(() => import('./pages/AnimalsPage'));
const AnimalProfilePage = loadable(() => import('./pages/AnimalProfilePage'));
const AnimalReportPage = loadable(() => import('./pages/AnimalReportPage'));
const ApplicationsSettingsPage = loadable(() => import('./pages/ApplicationsSettingsPage'));
const ArchivedCommunityProfilesPage = loadable(() => import('./pages/ArchivedCommunityProfilesPage'));
const CommunityPage = loadable(() => import('./pages/CommunityPage'));
const CommunityProfilePage = loadable(() => import('./pages/CommunityProfilePage'));
const CommunitySettingsPage = loadable(() => import('./pages/CommunitySettingsPage'));
const DashboardPage = loadable(() => import('./pages/DashboardPage'));
const ForgotPasswordPage = loadable(() => import('./pages/ForgotPasswordPage'));
const QuestionSettingsPage = loadable(() => import('./pages/ApplicationsSettingsPage/subpages/QuestionSettingsPage'));
const StatusEmailPage = loadable(() => import('./pages/ApplicationsSettingsPage/subpages/StatusEmailPage'));
const CustomTermsAndConditionsPage = loadable(
  () => import('./pages/ApplicationsSettingsPage/subpages/CustomTermsAndConditionsPage'),
);
const LandingPage = loadable(() => import('./pages/LandingPage'));
const RegistrationConfirmPage = loadable(() => import('./pages/RegistrationConfirmPage'));
const LoginPage = loadable(() => import('./pages/LoginPage'));
const LogoutPage = loadable(() => import('./pages/LogoutPage'));
// TODO: de-scoped for MVP
// const MarketingSettingsPage = loadable(() => import('./pages/MarketingSettingsPage'));
const NoMatchPage = loadable(() => import('./pages/NoMatchPage'));
const NotificationsAndPrivacySettingsPage = loadable(() => import('./pages/NotificationsAndPrivacySettingsPage'));
const PrivacyPolicyPage = loadable(() => import('./pages/PrivacyPolicyPage'));
const ProfileSettingsPage = loadable(() => import('./pages/ProfileSettingsPage'));
const RegisterAnimalPage = loadable(() => import('./pages/RegisterAnimalPage'));
const RegistrationPage = loadable(() => import('./pages/RegistrationPage'));
const ResetPasswordPage = loadable(() => import('./pages/ResetPasswordPage'));
const SearchPage = loadable(() => import('./pages/SearchPage'));
const ShelterSettingsPage = loadable(() => import('./pages/ShelterSettingsPage'));
const TermsAndConditionsPage = loadable(() => import('./pages/TermsAndConditionsPage'));
const TasksPage = loadable(() => import('./pages/TasksPage'));
// const SupportPage = loadable(() => import('./pages/SupportPage'));
const FaqPage = loadable(() => import('./pages/FaqPage'));
const RegistrationAcceptInvitePage = loadable(() => import('./pages/RegistrationAcceptInvitePage'));
const CreateNewShelterSettingsPage = loadable(() => import('./pages/CreateNewShelterSettingsPage'));
const ResendActivationEmailPage = loadable(() => import('./pages/ResendActivationEmailPage'));
const WidgetsPage = loadable(() => import('./pages/WidgetsPage'));
const UnauthorizedPage = loadable(() => import('./pages/UnauthorizedPage'));
const SummaryReportPage = loadable(() => import('./pages/SummaryReportPage'));

const faqRoutes = Faqs.map((faq) => (
  <Route key={faq.id} exact path={`${routes.pages.faqs.root}/${faq.route}`}>
    <FaqPage id={faq.id} />
  </Route>
));

const Routes = (): JSX.Element => {
  const dispatch = useDispatch();

  const HAS_ACCESS_LEVEL_2 = useSelector(hasAccessLevel2);
  const IS_AUTHENTICATED = useSelector(isAuthenticated);
  const PERSON_ID = useSelector(getPersonId);
  const SHELTER_ID = useSelector(getShelterId);

  useEffect(() => {
    if (!IS_AUTHENTICATED || !PERSON_ID || !SHELTER_ID) return;

    pushGtmEvent({
      event: 'USER',
      PersonID: PERSON_ID,
      ShelterID: SHELTER_ID,
    });

    dispatch(thunkUpdatePersonDetails());

    if (HAS_ACCESS_LEVEL_2) dispatch(thunkUpdateShelterDetails());
  }, [IS_AUTHENTICATED, PERSON_ID, SHELTER_ID, HAS_ACCESS_LEVEL_2]);

  return (
    <Router>
      <RouteSwitch>
        <Switch>
          <PrivateRoute exact path={routes.pages.settings.account} component={AccountSettingsPage} />
          <PrivateRoute exact path={routes.pages.settings.applications.root} component={ApplicationsSettingsPage} />
          <PrivateRoute exact path={routes.pages.settings.applications.question} component={QuestionSettingsPage} />
          <PrivateRoute exact path={routes.pages.settings.applications.statusEmail} component={StatusEmailPage} />
          <PrivateRoute
            exact
            path={routes.pages.settings.applications.customTerms}
            component={CustomTermsAndConditionsPage}
          />
          <PrivateRoute exact path={routes.pages.settings.community} component={CommunitySettingsPage} />

          <PrivateRoute exact path={routes.pages.settings.marketing} component={WidgetsPage} />
          <PrivateRoute
            exact
            path={routes.pages.settings.notificationsAndPrivacy}
            component={NotificationsAndPrivacySettingsPage}
          />
          <PrivateRoute exact path={routes.pages.settings.createNewShelter} component={CreateNewShelterSettingsPage} />
          <PrivateRoute
            exact
            path={routes.pages.settings.profile}
            component={ProfileSettingsPage}
            scope={Scope.Personal.SettingsView}
          />
          <PrivateRoute
            exact
            path={routes.pages.settings.shelter}
            component={ShelterSettingsPage}
            scope={Scope.Shelter.SettingsView}
          />
          <PrivateRoute exact path={routes.pages.animals.root} component={AnimalsPage} />
          <PrivateRoute path={routes.pages.animals.activityLog()} component={ActivityLogPage} />
          <PrivateRoute path={routes.pages.animals.profile()}>
            <PrivateRoute component={AnimalProfilePage} scope={Scope.Animal.Profile.View} />
          </PrivateRoute>
          <PrivateRoute path={routes.pages.animals.litter()} component={AnimalLitterPage} />
          <PrivateRoute exact path={routes.pages.animals.register} component={RegisterAnimalPage} />
          <PrivateRoute path={routes.pages.animals.application.forced()} component={AnimalForcedApplicationPage} />
          <PrivateRoute exact path={routes.pages.animals.application.root} component={AnimalApplicationsPage} />
          <PrivateRoute exact path={routes.pages.animals.application.byStatus()} component={AnimalApplicationsPage} />
          <PrivateRoute exact path={routes.pages.animals.application.byAnimal()} component={AnimalApplicationsPage} />
          <PrivateRoute
            exact
            path={routes.pages.animals.application.byAnimalAndStatus()}
            component={AnimalApplicationsPage}
          />
          <PrivateRoute exact path={routes.pages.animals.application.byPerson()} component={AnimalApplicationsPage} />
          <PrivateRoute
            exact
            path={routes.pages.animals.application.byPersonAndStatus()}
            component={AnimalApplicationsPage}
          />
          <PrivateRoute path={routes.pages.animals.application.byId()} component={AnimalApplicationPage} />
          <PrivateRoute exact path={routes.pages.animals.report} component={AnimalReportPage} />
          <PrivateRoute exact path={routes.pages.tasks.root} component={TasksPage} scope={Scope.Task.View} />
          <PrivateRoute exact path={routes.pages.community.root} component={CommunityPage} />
          <PrivateRoute
            path={routes.pages.community.user()}
            component={CommunityProfilePage}
            scope={Scope.Community.Profile.View}
          >
            <PrivateRoute component={CommunityProfilePage} scope={Scope.Community.Profile.View} />
          </PrivateRoute>
          <PrivateRoute exact path={routes.pages.community.archived} component={ArchivedCommunityProfilesPage} />

          <Route exact path={routes.pages.forgotPassword}>
            <ForgotPasswordPage />
          </Route>
          <Route exact path={routes.pages.login}>
            <LoginPage />
          </Route>
          <Route exact path={routes.pages.logout}>
            <LogoutPage />
          </Route>
          <Route exact path={routes.pages.registration.root}>
            <RegistrationPage />
          </Route>
          <Route exact path={routes.pages.resetPassword}>
            <ResetPasswordPage />
          </Route>
          <Route exact path={routes.pages.registration.confirm}>
            <RegistrationConfirmPage />
          </Route>
          <Route exact path={routes.pages.registration.acceptInvite}>
            <RegistrationAcceptInvitePage />
          </Route>
          <Route exact path={routes.pages.privacyPolicy}>
            <PrivacyPolicyPage />
          </Route>
          <Route exact path={routes.pages.termsAndConditionsPage}>
            <TermsAndConditionsPage />
          </Route>
          <PrivateRoute exact path={routes.pages.search.applications} component={SearchPage} />
          <PrivateRoute exact path={routes.pages.search.community} component={SearchPage} />
          <PrivateRoute exact path={routes.pages.search.animals} component={SearchPage} />
          <PrivateRoute exact path={routes.pages.search.root} component={SearchPage} />
          <PrivateRoute exact path={routes.pages.dashboard} component={DashboardPage} />
          {IS_DEV ? <PrivateRoute exact path={routes.pages.summary.root} component={SummaryReportPage} /> : null}
          <Route exact path={routes.pages.root} component={LandingPage} />
          <Route exact path={routes.pages.resendActivationEmail} component={ResendActivationEmailPage} />
          <Route exact path={routes.pages.faqs.root} component={FaqPage} />
          {faqRoutes}
          <Route exact path={routes.pages.unauthorized} component={UnauthorizedPage} />
          <Route component={NoMatchPage} />
        </Switch>
      </RouteSwitch>
    </Router>
  );
};

export default Routes;
