import {
  Container,
  Heading,
  Layout,
  LayoutContent,
  LayoutHeader,
  PageTransition,
  Text,
} from '@colensobbdo/shelter-management-frontend';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import Header from '../../components/Header';

const UnauthorizedPage = (): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'pages.unauthorized.page-title' })}</title>
      </Helmet>
      <PageTransition>
        <Layout>
          <LayoutHeader>
            <Header enableBackButton>
              <Heading level="h3" tag="h1">
                <FormattedMessage id="pages.unauthorized.title" />
              </Heading>
            </Header>
          </LayoutHeader>
          <LayoutContent>
            <Container>
              <Text align="center">
                <FormattedMessage id="pages.unauthorized.content" />
              </Text>
            </Container>
          </LayoutContent>
        </Layout>
      </PageTransition>
    </>
  );
};

export default UnauthorizedPage;
