import CONFIG from '~/configs';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ActivationContent,
  AddOrDeletePetContent,
  AdoptionApplicationsContent,
  AnimalDataContent,
  AnimalReadyContent,
  CustomiseShelterContent,
  InvitingShelterContent,
  RegisterContent,
  WhatDoesMyHoomanCostContent,
  WhatHappensToDataContent,
  WhatIsMyHoomanContent,
  WhoCanAccessContent,
} from './FaqContents';
import { FaqType } from './types';

export const FaqSections = [
  {
    category: 'faq',
    title: <FormattedMessage id={'pages.faqs.category.faq.title'} />,
  },
  {
    category: 'setting-up',
    title: <FormattedMessage id={'pages.faqs.category.setting-up.title'} />,
  },
  {
    category: 'profiles',
    title: <FormattedMessage id={'pages.faqs.category.profiles.title'} />,
  },
  {
    category: 'adoption',
    title: <FormattedMessage id={'pages.faqs.category.adoption.title'} />,
  },
  {
    category: 'help',
    title: <FormattedMessage id={'pages.faqs.category.help.title'} />,
  },
  {
    category: 'contact',
    title: <FormattedMessage id={'pages.faqs.category.contact.title'} />,
  },
];

export const Faqs: FaqType[] = [
  {
    id: 'landing',
    route: '',
    title: <FormattedMessage id={'pages.faqs.page-title'} />,
    category: 'landing',
  },
  {
    id: 'what-is-myhooman',
    route: 'what-is-myhooman',
    title: <FormattedMessage id={'pages.faqs.question.what-is-myhooman.title'} />,
    content: <WhatIsMyHoomanContent />,
    category: 'faq',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'how-much-does-myhooman-cost',
    route: 'how-much-does-myhooman-cost',
    title: <FormattedMessage id={'pages.faqs.question.how-much-does-myhooman-cost.title'} />,
    content: <WhatDoesMyHoomanCostContent />,
    category: 'faq',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'what-happens-to-mine-and-shelters-data',
    route: 'what-happens-to-mine-and-shelters-data',
    title: <FormattedMessage id={'pages.faqs.question.what-happens-to-mine-and-shelters-data.title'} />,
    content: <WhatHappensToDataContent />,
    category: 'faq',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'who-can-access-myhooman-account',
    route: 'who-can-access-myhooman-account',
    title: <FormattedMessage id={'pages.faqs.question.who-can-access-myhooman-account.title'} />,
    content: <WhoCanAccessContent />,
    category: 'faq',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'registering-a-shelter',
    route: 'registering-a-shelter',
    title: <FormattedMessage id={'pages.faqs.question.registering-a-shelter.title'} />,
    content: <RegisterContent />,
    category: 'setting-up',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'activation-links',
    route: 'activation-links',
    title: <FormattedMessage id={'pages.faqs.question.activation-links.title'} />,
    content: <ActivationContent />,
    category: 'setting-up',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'inviting-people-to-your-shelter',
    route: 'inviting-people-to-your-shelter',
    title: <FormattedMessage id={'pages.faqs.question.inviting-people-to-your-shelter.title'} />,
    content: <InvitingShelterContent />,
    category: 'setting-up',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'customise-your-shelter',
    route: 'customise-your-shelter',
    title: <FormattedMessage id={'pages.faqs.question.customise-your-shelter.title'} />,
    content: <CustomiseShelterContent />,
    category: 'setting-up',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'add-or-delete-pet',
    route: 'add-or-delete-pet',
    title: <FormattedMessage id={'pages.faqs.question.add-or-delete-pet.title'} />,
    content: <AddOrDeletePetContent />,
    category: 'profiles',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'animal-data',
    route: 'animal-data',
    title: <FormattedMessage id={'pages.faqs.question.animal-data.title'} />,
    content: <AnimalDataContent />,
    category: 'profiles',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'animals-ready-for-adoption',
    route: 'animals-ready-for-adoption',
    title: <FormattedMessage id={'pages.faqs.question.animals-ready-for-adoption.title'} />,
    content: <AnimalReadyContent />,
    category: 'adoption',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'adoption-applications',
    route: 'adoption-applications',
    title: <FormattedMessage id={'pages.faqs.question.adoption-applications.title'} />,
    content: <AdoptionApplicationsContent />,
    category: 'adoption',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'how-to-guide',
    route: 'how-to-guide',
    title: <FormattedMessage id={'pages.faqs.help.how-to-guide.title'} />,
    category: 'help',
    href: CONFIG.assetPath + 'help/MyHooman%20Instruction%20Manual.pdf',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'contact-email',
    route: '',
    title: <FormattedMessage id={'pages.faqs.contact.email.title'} />,
    category: 'contact',
    href: 'mailto:info@myhooman.co.nz',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'contact-phone',
    route: '',
    title: <FormattedMessage id={'pages.faqs.contact.phone.title'} />,
    category: 'contact',
    href: 'tel:080069466626',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
];
