import { IUserGroupLookup } from '@colensobbdo/shelter-management-frontend-integration';

// state

export type UserGroup = IUserGroupLookup;

export interface UserGroupState {
  list: UserGroup[];
  listUpdatedOn: number;
}

// action

export const USER_GROUP_LIST_UPDATE = 'USER_GROUP_LIST_UPDATE';
export const USER_GROUP_LIST_CLEAR = 'USER_GROUP_LIST_CLEAR';

interface UserGroupListUpdateAction {
  readonly type: typeof USER_GROUP_LIST_UPDATE;
  readonly payload: UserGroup[];
}

interface UserGroupListClearAction {
  readonly type: typeof USER_GROUP_LIST_CLEAR;
}

export type UserGroupActionTypes = UserGroupListUpdateAction | UserGroupListClearAction;
