import { USER_GROUP_LIST_CLEAR, USER_GROUP_LIST_UPDATE, UserGroupActionTypes, UserGroupState } from './types';

const initialState: UserGroupState = {
  list: [],
  listUpdatedOn: 0,
};

export function usergroupsReducer(state = initialState, action: UserGroupActionTypes): UserGroupState {
  switch (action.type) {
    case USER_GROUP_LIST_UPDATE:
      return {
        ...state,
        list: [...action.payload],
        listUpdatedOn: new Date().getTime(),
      };
    case USER_GROUP_LIST_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
