import { AnimalSortOrderType } from '@colensobbdo/shelter-management-frontend-integration';

import {
  DashboardActionTypes,
  DashboardState,
  SET_ANIMALS_LIST_IN_MY_CARE,
  SET_ANIMALS_LIST_SORT_ORDER,
  SET_FILTER_BY,
  SET_PAGELOAD_MODAL,
  SET_VIEW_BY,
} from './types';

const initialState: DashboardState = {
  animalsListSortOrder: AnimalSortOrderType.Descending,
  animalsListInMyCare: undefined,
  viewBy: undefined,
  filterBy: undefined,
  pageloadModal: null,
};

export function dashboardReducer(state = initialState, action: DashboardActionTypes): DashboardState {
  switch (action.type) {
    case SET_ANIMALS_LIST_SORT_ORDER:
      return {
        ...state,
        animalsListSortOrder: action.payload,
      };

    case SET_ANIMALS_LIST_IN_MY_CARE:
      return {
        ...state,
        animalsListInMyCare: action.payload,
      };

    case SET_VIEW_BY:
      return {
        ...state,
        viewBy: action.payload,
      };

    case SET_FILTER_BY:
      return {
        ...state,
        filterBy: action.payload,
      };

    case SET_PAGELOAD_MODAL:
      return {
        ...state,
        pageloadModal: action.payload,
      };

    default:
      return state;
  }
}
