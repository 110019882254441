import {
  AnimalSpecieCategoryId,
  CountryIdType,
  LanguageIdType,
  PersonIdType,
  ShelterPersonIdType,
} from '@colensobbdo/shelter-management-frontend-integration';
import { UserGroup } from 'store/usergroups/types';

export interface PersonState {
  personId: PersonIdType | null;
  shelterPersonId: ShelterPersonIdType | null;
  countryId: CountryIdType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  photoUrl: string | null;
  userTypes: string[] | null;
  userGroups: UserGroup[] | null;
  isActive: boolean;
  languageId: LanguageIdType | null;
  fosterEnabledSpecies: AnimalSpecieCategoryId[];
}

export const PERSON_DATA_UPDATE = 'PERSON_DATA_UPDATE';
export const PERSON_DATA_CLEAR = 'PERSON_DATA_CLEAR';

export type PersonDataUpdatePayload = Partial<PersonState>;

interface PersonDataUpdateAction {
  type: typeof PERSON_DATA_UPDATE;
  payload: PersonDataUpdatePayload;
}

interface PersonDataClearAction {
  type: typeof PERSON_DATA_CLEAR;
}

export type PersonActionTypes = PersonDataUpdateAction | PersonDataClearAction;
