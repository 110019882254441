import { SEARCH_CLEAR, SEARCH_STORE, SearchActionTypes, SearchStoreLastActionPayload } from './types';

export function searchStoreLast(data: SearchStoreLastActionPayload): SearchActionTypes {
  return {
    type: SEARCH_STORE,
    payload: data,
  };
}

export function searchClearHistory(): SearchActionTypes {
  return {
    type: SEARCH_CLEAR,
  };
}
