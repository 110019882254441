import _get from 'lodash/get';
import _omit from 'lodash/omit';

import {
  COMMUNITY_LIST_CACHE_TIME,
  COMMUNITY_LIST_DATA_CLEAR,
  COMMUNITY_LIST_DATA_LOAD_END,
  COMMUNITY_LIST_DATA_LOAD_START,
  COMMUNITY_LIST_DATA_PAGE_LOADED,
  COMMUNITY_LIST_DATA_PAGE_LOAD_START,
  COMMUNITY_LIST_INVALIDATE_ACTION,
  CommunityActionTypes,
  CommunityState,
} from './types';

const initialState: CommunityState = {
  invalidationDate: 0,
  list: {
    rows: [],
    isLoading: false,
    loadedOn: 0,
    expiresOn: 0,
    pagination: null,
    filters: {},
    error: null,
  },
};

export function communityReducer(state = initialState, action: CommunityActionTypes): CommunityState {
  switch (action.type) {
    case COMMUNITY_LIST_DATA_CLEAR:
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      };

    case COMMUNITY_LIST_INVALIDATE_ACTION:
      return {
        ...state,
        invalidationDate: new Date().getTime(),
      };

    case COMMUNITY_LIST_DATA_LOAD_START:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          filters: _omit(action.payload, ['page', 'pageSize']),
          error: null,
        },
      };

    case COMMUNITY_LIST_DATA_LOAD_END:
      return {
        ...state,
        list: {
          ...state.list,
          rows: _get(action, 'payload.data', []),
          isLoading: false,
          loadedOn: new Date().getTime(),
          expiresOn: new Date().getTime() + COMMUNITY_LIST_CACHE_TIME,
          pagination: _get(action, 'payload.pagination', null),
          error: _get(action, 'payload.error.error', null),
        },
      };

    case COMMUNITY_LIST_DATA_PAGE_LOAD_START:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          error: null,
        },
      };

    case COMMUNITY_LIST_DATA_PAGE_LOADED:
      return {
        ...state,
        list: {
          ...state.list,
          rows: [...state.list.rows, ..._get(action, 'payload.data', [])],
          isLoading: false,
          pagination: _get(action, 'payload.pagination', null),
          error: _get(action, 'payload.error.error', null),
        },
      };

    default:
      return state;
  }
}
