import { CreatePerson, CreateShelter } from '@colensobbdo/shelter-management-frontend-integration';

export type RegistrationStepEnum =
  | 'CompleteRegistrationStep'
  | 'ActivationRequestConfirmation'
  | 'CompleteShelterStep'
  | 'CreatePersonStep'
  | 'CreateShelterStep'
  | 'UpdatePersonStep'
  | 'ConfirmationStep'
  | 'SetupFirstTimeProfileStep'
  | 'SetupVolunteerProfileStep'
  | 'SetupFosterProfileStep'
  | 'SetupFosterTagsStep'
  | 'SetupVetProfileStep'
  | 'LicensingAgreementStep'
  | 'PrivacyPolicyStep'
  | 'TermsAndConditionsStep'
  | 'AllUpdatesStep'
  | 'MarketingStep'
  | 'AnalyticsStep'
  | 'ProfileCreatedStep';

export type IRegistrationUserDetails = CreatePerson.ValidateRequest;
export type IRegistrationShelterDetails = CreateShelter.ValidateRequest;

export interface IRegistrationMarketingDetails {
  optInMarketingMyHooman: boolean;
  optInMarketingMarsAndAffiliates: boolean;
}

export interface IRegistrationState extends IRegistrationMarketingDetails {
  step: RegistrationStepEnum;
  isCreateNewShelterFlow: boolean;
  isInviteUserFlow: boolean;
  isNewUser: boolean;
  isVolunteer: boolean;
  isFoster: boolean;
  isVet: boolean;
  user: IRegistrationUserDetails | null;
  shelter: IRegistrationShelterDetails | null;
  optInAllUpdates: boolean;
  optInForAnalytics: boolean;
}

export const SET_REGISTRATION_STEP = 'SET_REGISTRATION_STEP';
export const SET_REGISTRATION_FLAGS = 'SET_REGISTRATION_FLAGS';
export const SET_REGISTRATION_USER_DETAILS = 'SET_REGISTRATION_USER_DETAILS';
export const SET_REGISTRATION_SHELTER_DETAILS = 'SET_REGISTRATION_SHELTER_DETAILS';
export const SET_REGISTRATION_MARKETING_PREFERENCE = 'SET_REGISTRATION_MARKETING_PREFERENCE';
export const SET_REGISTRATION_ALL_UPDATES_PREFERENCE = 'SET_REGISTRATION_ALL_UPDATES_PREFERENCE';
export const SET_REGISTRATION_ANALYTICS_PREFERENCE = 'SET_REGISTRATION_ANALYTICS_PREFERENCE';

interface ISetRegistrationStepAction {
  type: typeof SET_REGISTRATION_STEP;
  payload: RegistrationStepEnum;
}

export type RegistrationFlagsPayload =
  | {
      isCreateNewShelterFlow: boolean;
    }
  | {
      isInviteUserFlow: boolean;
      isNewUser: boolean;
      isVolunteer: boolean;
      isFoster: boolean;
      isVet: boolean;
    };

interface ISetRegistrationFlagsAction {
  type: typeof SET_REGISTRATION_FLAGS;
  payload: RegistrationFlagsPayload;
}

interface ISetRegistrationUserDetailsAction {
  type: typeof SET_REGISTRATION_USER_DETAILS;
  payload: IRegistrationUserDetails;
}

interface ISetRegistrationShelterDetailsAction {
  type: typeof SET_REGISTRATION_SHELTER_DETAILS;
  payload: IRegistrationShelterDetails;
}

interface ISetRegistrationMarketingPreferenceAction {
  type: typeof SET_REGISTRATION_MARKETING_PREFERENCE;
  payload: IRegistrationMarketingDetails;
}

interface ISetRegistrationAllUpdatesPreferenceAction {
  type: typeof SET_REGISTRATION_ALL_UPDATES_PREFERENCE;
  payload: boolean;
}

interface ISetRegistrationAnalyticsPreferenceAction {
  type: typeof SET_REGISTRATION_ANALYTICS_PREFERENCE;
  payload: boolean;
}

export type RegistrationActionTypes =
  | ISetRegistrationStepAction
  | ISetRegistrationFlagsAction
  | ISetRegistrationUserDetailsAction
  | ISetRegistrationShelterDetailsAction
  | ISetRegistrationMarketingPreferenceAction
  | ISetRegistrationAllUpdatesPreferenceAction
  | ISetRegistrationAnalyticsPreferenceAction;
