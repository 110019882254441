import _get from 'lodash/get';

import {
  APPLICATION_LIST_DATA_CLEAR,
  APPLICATION_LIST_DATA_LOAD_END,
  APPLICATION_LIST_DATA_PAGE_LOADED,
  APPLICATION_LIST_LOAD_START,
  ApiDataType,
  ApplicationActionTypes,
  ApplicationState,
} from './types';

const initialState: ApplicationState = {
  animalName: null,
  list: {
    rows: [],
    pagination: null,
    isLoading: false,
    error: null,
  },
};

export function applicationsReducer(state = initialState, action: ApplicationActionTypes): ApplicationState {
  switch (action.type) {
    case APPLICATION_LIST_DATA_CLEAR:
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      };
    case APPLICATION_LIST_LOAD_START:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          error: null,
        },
      };
    case APPLICATION_LIST_DATA_LOAD_END:
      return {
        ...state,
        animalName:
          action.payload.type === ApiDataType.ANIMAL ? _get(action, 'payload.api.data.animalName', null) : null,
        list: {
          ...state.list,
          rows:
            action.payload.type === ApiDataType.SHELTER
              ? [..._get(action, 'payload.api.data', [])]
              : [..._get(action, 'payload.api.data.applications', [])],
          pagination: _get(action, 'payload.api.pagination', null),
          isLoading: false,
          error: _get(action, 'payload.api.error.error', null),
        },
      };

    case APPLICATION_LIST_DATA_PAGE_LOADED:
      return {
        ...state,
        animalName:
          action.payload.type === ApiDataType.ANIMAL ? _get(action, 'payload.api.data.animalName', null) : null,
        list: {
          ...state.list,
          rows:
            action.payload.type === ApiDataType.SHELTER
              ? [...state.list.rows, ..._get(action, 'payload.api.data', [])]
              : [...state.list.rows, ..._get(action, 'payload.api.data.applications', [])],
          pagination: _get(action, 'payload.api.pagination', null),
          isLoading: false,
          error: _get(action, 'payload.api.error.error', null),
        },
      };

    default:
      return state;
  }
}
