import { CommunitySearch, IPagination } from '@colensobbdo/shelter-management-frontend-integration';

export const COMMUNITY_LIST_CACHE_TIME = 15 * 60 * 1000; // 15 min
export const COMMUNITY_LIST_PAGE_SIZE = 60;

export type CommunityFilters = Omit<CommunitySearch.Request, 'page' | 'pageSize'>;

export interface CommunityListState {
  rows: CommunitySearch.ResponseData;
  isLoading: boolean;
  loadedOn: number;
  expiresOn: number;
  pagination: IPagination | null;
  filters: CommunityFilters;
  error: string | null;
}

export interface CommunityState {
  invalidationDate: number;
  list: CommunityListState;
}

export const COMMUNITY_LIST_DATA_CLEAR = 'COMMUNITY_LIST_DATA_CLEAR';
export const COMMUNITY_LIST_INVALIDATE_ACTION = 'COMMUNITY_LIST_INVALIDATE_ACTION';
export const COMMUNITY_LIST_DATA_LOAD_START = 'COMMUNITY_LIST_DATA_LOAD_START';
export const COMMUNITY_LIST_DATA_LOAD_END = 'COMMUNITY_LIST_DATA_LOAD_END';
export const COMMUNITY_LIST_DATA_PAGE_LOAD_START = 'COMMUNITY_LIST_DATA_PAGE_LOAD_START';
export const COMMUNITY_LIST_DATA_PAGE_LOADED = 'COMMUNITY_LIST_DATA_PAGE_LOADED';

interface CommunityListDataClearAction {
  type: typeof COMMUNITY_LIST_DATA_CLEAR;
}

interface CommunityListInvalidateAction {
  type: typeof COMMUNITY_LIST_INVALIDATE_ACTION;
}

interface CommunityListDataLoadStartAction {
  type: typeof COMMUNITY_LIST_DATA_LOAD_START;
  payload: CommunityFilters;
}

interface CommunityListDataLoadEndAction {
  type: typeof COMMUNITY_LIST_DATA_LOAD_END;
  payload: CommunitySearch.Response;
}

interface CommunityListDataPageLoadStartAction {
  type: typeof COMMUNITY_LIST_DATA_PAGE_LOAD_START;
}

interface CommunityListDataPageLoadEndAction {
  type: typeof COMMUNITY_LIST_DATA_PAGE_LOADED;
  payload: CommunitySearch.Response;
}

export type CommunityActionTypes =
  | CommunityListDataClearAction
  | CommunityListInvalidateAction
  | CommunityListDataLoadStartAction
  | CommunityListDataLoadEndAction
  | CommunityListDataPageLoadStartAction
  | CommunityListDataPageLoadEndAction;
