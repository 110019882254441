import { makeLoggers } from '@colensobbdo/shelter-management-frontend-integration';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { Dispatch } from 'react';
import { GetState } from 'store/store';

import { getUserGroups } from '../person/selectors';
import { getNextRegistrationStep } from './selectors';
import {
  IRegistrationMarketingDetails,
  IRegistrationShelterDetails,
  IRegistrationUserDetails,
  RegistrationActionTypes,
  RegistrationFlagsPayload,
  RegistrationStepEnum,
  SET_REGISTRATION_ALL_UPDATES_PREFERENCE,
  SET_REGISTRATION_ANALYTICS_PREFERENCE,
  SET_REGISTRATION_FLAGS,
  SET_REGISTRATION_MARKETING_PREFERENCE,
  SET_REGISTRATION_SHELTER_DETAILS,
  SET_REGISTRATION_STEP,
  SET_REGISTRATION_USER_DETAILS,
} from './types';

const { log } = makeLoggers('state :: registration');

export function setRegistrationStep(payload: RegistrationStepEnum): RegistrationActionTypes {
  return {
    type: SET_REGISTRATION_STEP,
    payload,
  };
}

export function updateRegistrationFlags(flags: RegistrationFlagsPayload) {
  return async (dispatch: Dispatch<any>, getState: GetState): Promise<void> => {
    log('updateRegistrationFlags', flags);

    if (_get(flags, 'isInviteUserFlow')) {
      const stateBefore = getState();
      const USER_GROUPS = getUserGroups(stateBefore);
      log('updateRegistrationFlags :: user groups', USER_GROUPS);

      const PROFILE_IS_MASTER = !!_find(USER_GROUPS, { name: 'Shelter Master' });
      const PROFILE_IS_ADMIN = !!_find(USER_GROUPS, { name: 'Shelter Admin' });
      const PROFILE_IS_VOLUNTEER = !!_find(USER_GROUPS, { name: 'Volunteers' });
      const PROFILE_IS_STAFF = !!_find(USER_GROUPS, { name: 'Shelter Staff' });
      const PROFILE_IS_FOSTER = !!_find(USER_GROUPS, { name: 'Fosters' }) || PROFILE_IS_MASTER;
      const PROFILE_IS_VET = !!_find(USER_GROUPS, { name: 'Vets' });
      const PROFILE_IS_STAFF_MEMBER = PROFILE_IS_VOLUNTEER || PROFILE_IS_ADMIN || PROFILE_IS_STAFF || PROFILE_IS_MASTER;
      log('updateRegistrationFlags :: user groups', {
        PROFILE_IS_MASTER,
        PROFILE_IS_ADMIN,
        PROFILE_IS_VOLUNTEER,
        PROFILE_IS_STAFF,
        PROFILE_IS_FOSTER,
        PROFILE_IS_VET,
        PROFILE_IS_STAFF_MEMBER,
      });

      flags = {
        ...flags,
        isInviteUserFlow: true,
        isVolunteer: PROFILE_IS_STAFF_MEMBER || _get(flags, 'isVolunteer', false),
        isFoster: PROFILE_IS_FOSTER || _get(flags, 'isFoster', false),
        isVet: PROFILE_IS_VET || _get(flags, 'isVet', false),
      };
      log('updateRegistrationFlags :: new flags', flags);
    }

    dispatch({
      type: SET_REGISTRATION_FLAGS,
      payload: flags,
    });

    const stateAfter = getState();
    const nextStep = getNextRegistrationStep(stateAfter);

    dispatch(setRegistrationStep(nextStep));
  };
}

export function storeNewUserDetails(data: IRegistrationUserDetails): RegistrationActionTypes {
  return {
    type: SET_REGISTRATION_USER_DETAILS,
    payload: data,
  };
}

export function storeNewShelterDetails(data: IRegistrationShelterDetails): RegistrationActionTypes {
  return {
    type: SET_REGISTRATION_SHELTER_DETAILS,
    payload: data,
  };
}

export function storeNewUserAllUpdatesPreference(data: boolean): RegistrationActionTypes {
  return {
    type: SET_REGISTRATION_ALL_UPDATES_PREFERENCE,
    payload: data,
  };
}

export function storeNewUserMarketingPreference(data: IRegistrationMarketingDetails): RegistrationActionTypes {
  return {
    type: SET_REGISTRATION_MARKETING_PREFERENCE,
    payload: data,
  };
}

export function storeNewUserAnalyticsPreference(enabled: boolean): RegistrationActionTypes {
  return {
    type: SET_REGISTRATION_ANALYTICS_PREFERENCE,
    payload: enabled,
  };
}
