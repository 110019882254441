import _get from 'lodash/get';
import _omit from 'lodash/omit';

import {
  ANIMAL_LIST_CACHE_TIME,
  ANIMAL_LIST_DATA_CLEAR,
  ANIMAL_LIST_DATA_LOAD_END,
  ANIMAL_LIST_DATA_LOAD_START,
  ANIMAL_LIST_DATA_PAGE_LOADED,
  ANIMAL_LIST_DATA_PAGE_LOAD_START,
  ANIMAL_LIST_INVALIDATE_ACTION,
  ANIMAL_LIST_SET_ARCHIVE_MODE,
  ANIMAL_LIST_SET_LOADING_ONLY,
  AnimalsActionTypes,
  AnimalsState,
} from './types';

const initialState: AnimalsState = {
  invalidationDate: 0,
  isArchiveMode: false,
  list: {
    rows: [],
    applicationByStatus: [],
    isLoading: false,
    isPageLoading: false,
    loadedOn: 0,
    expiresOn: 0,
    pagination: null,
    filters: {},
    error: null,
    archiveDate: null,
  },
};

export function animalsReducer(state = initialState, action: AnimalsActionTypes): AnimalsState {
  switch (action.type) {
    case ANIMAL_LIST_DATA_CLEAR:
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      };
    case ANIMAL_LIST_INVALIDATE_ACTION:
      return {
        ...state,
        invalidationDate: new Date().getTime(),
      };
    case ANIMAL_LIST_DATA_LOAD_START:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          filters: _omit(action.payload, ['page', 'pageSize']),
          error: null,
        },
      };
    case ANIMAL_LIST_DATA_LOAD_END:
      return {
        ...state,
        list: {
          ...state.list,
          rows: _get(action, 'payload.data.animals', []),
          applicationByStatus: _get(action, 'payload.data.applications', []),
          isLoading: false,
          loadedOn: new Date().getTime(),
          expiresOn: new Date().getTime() + ANIMAL_LIST_CACHE_TIME,
          pagination: _get(action, 'payload.pagination', null),
          error: _get(action, 'payload.error.error', null),
          archiveDate: _get(action, 'payload.archiveDate', null),
        },
      };
    case ANIMAL_LIST_DATA_PAGE_LOAD_START:
      return {
        ...state,
        list: {
          ...state.list,
          isPageLoading: true,
          error: null,
        },
      };
    case ANIMAL_LIST_DATA_PAGE_LOADED:
      return {
        ...state,
        list: {
          ...state.list,
          rows: [...state.list.rows, ..._get(action, 'payload.data.animals', [])],
          applicationByStatus: _get(action, 'payload.data.applications', []),
          isPageLoading: false,
          pagination: _get(action, 'payload.pagination', null),
          error: _get(action, 'payload.error.error', null),
        },
      };
    case ANIMAL_LIST_SET_LOADING_ONLY:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: action.payload,
        },
      };
    case ANIMAL_LIST_SET_ARCHIVE_MODE:
      return {
        ...state,
        isArchiveMode: action.payload,
      };
    default:
      return state;
  }
}
