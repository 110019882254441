import {
  setAccessTokenGetter,
  setLogoutHandler,
  setRefreshTokenGetter,
  setRefreshTokenSetter,
} from '@colensobbdo/shelter-management-frontend-integration';
import { Action, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';

import { animalsReducer } from './animals/reducers';
import { applicationsReducer } from './applications/reducers';
import { authReducer } from './auth/reducers';
import { getAccessToken, getRefreshToken } from './auth/selectors';
import { thunkSaveTokens } from './auth/thunks';
import { communityReducer } from './community/reducers';
import { dashboardReducer } from './dashboard/reducers';
import { logout } from './logout/actions';
import { personReducer } from './person/reducers';
import { registrationReducer } from './registration/reducers';
import { searchReducer } from './search/reducers';
import { shelterReducer } from './shelter/reducers';
import { tasksReducer } from './tasks/reducers';
import { usergroupsReducer } from './usergroups/reducers';
import { usernameReducer } from './username/reducers';

const rootReducer = combineReducers({
  animals: animalsReducer,
  applications: applicationsReducer,
  auth: authReducer,
  community: communityReducer,
  dashboard: dashboardReducer,
  person: personReducer,
  registration: registrationReducer,
  search: searchReducer,
  shelter: shelterReducer,
  tasks: tasksReducer,
  usergroups: usergroupsReducer,
  username: usernameReducer,
});

type State = ReturnType<typeof rootReducer>;

const persistConfig: PersistConfig<State> = {
  key: 'root',
  storage,
  blacklist: ['tasks', 'animals', 'applications', 'community'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunkMiddleware],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, void, Action>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type GetState = typeof store.getState;

// allow api to retrieve access/refresh token
setAccessTokenGetter(() => getAccessToken(store.getState()));
setRefreshTokenGetter(() => getRefreshToken(store.getState()));
setRefreshTokenSetter((tokens) => store.dispatch(thunkSaveTokens(tokens) as any));
setLogoutHandler(() => store.dispatch(logout() as any));
