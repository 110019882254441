import { Notification } from '@colensobbdo/shelter-management-frontend';
import React, { createContext, useState } from 'react';

export interface INotification {
  type: 'success' | 'error' | 'standard' | 'progress';
  message: string;
}

interface NotificationContextProps {
  addNotification: (_notification: INotification) => void;
}

interface NotificationProviderProps {
  children?: React.ReactNode;
}

const notifications: INotification[] = [];
let showing = false;
let _clear: NodeJS.Timeout;
let _show: NodeJS.Timeout;

export const NotificationContext = createContext({} as NotificationContextProps);

export const NotificationProvider = (props: NotificationProviderProps): JSX.Element => {
  const [notification, setNotification] = useState<INotification>();
  const [active, setActive] = useState(false);

  const addNotification = (_notification: INotification) => {
    notifications.push(_notification);
    if (!showing) showNotification();
  };

  const showNotification = async () => {
    showing = true;

    if (notifications.length === 0) {
      setNotification(undefined);
      showing = false;
      return;
    }

    setNotification(notifications.shift());
    setActive(true);

    _clear = setTimeout(() => clearNotification(), 3000);
  };

  const clearNotification = () => {
    clearTimeout(_clear);
    clearTimeout(_show);

    setActive(false);
    _show = setTimeout(() => showNotification(), 500);
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      <div onClick={() => clearNotification()} style={{ cursor: 'pointer' }}>
        <Notification active={active} type={notification?.type}>
          <strong>{notification?.message}</strong>
        </Notification>
      </div>
      {props.children}
    </NotificationContext.Provider>
  );
};
