import { PERSON_DATA_CLEAR, PERSON_DATA_UPDATE, PersonActionTypes, PersonState } from './types';

const initialState: PersonState = {
  countryId: null,
  email: null,
  firstName: null,
  lastName: null,
  personId: null,
  phone: null,
  photoUrl: null,
  shelterPersonId: null,
  userTypes: [],
  isActive: false,
  languageId: null,
  userGroups: [],
  fosterEnabledSpecies: [],
};

export function personReducer(state = initialState, action: PersonActionTypes): PersonState {
  switch (action.type) {
    case PERSON_DATA_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case PERSON_DATA_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
