import _filter from 'lodash/filter';
import _startsWith from 'lodash/startsWith';
import _take from 'lodash/take';
import moment from 'moment';

import { SEARCH_CLEAR, SEARCH_STORE, SearchActionTypes, SearchState } from './types';

const initialState: SearchState = {
  recentSearches: [],
};

/**
 * If search triggered on dashboard -  animals listed first. Launching search
 * from dashboard shows recent searches by account.
 * Max 3 recent results from last 90 days.
 * If no recent search, show nothing.
 */

const MAX_SEARCH_COUNT = 3;
const MAX_SEARCH_AGE = 90; // days

export function searchReducer(state = initialState, action: SearchActionTypes): SearchState {
  switch (action.type) {
    case SEARCH_STORE: {
      const newSearch = action.payload;

      const oldList = [...state.recentSearches];

      /**
       * Remove search older than 90 days
       */
      const filteredList = _filter(oldList, (data) => {
        return (
          moment(data.createdOn).diff(moment(), 'days') <= MAX_SEARCH_AGE &&
          data.text !== newSearch.text &&
          !_startsWith(newSearch.text, data.text)
        );
      });

      const newList = [
        {
          ...newSearch,
          createdOn: moment().format(),
        },
        ...filteredList,
      ];

      /**
       * Max 3 recent results
       */
      const slicedList = _take(newList, MAX_SEARCH_COUNT);

      return {
        ...state,
        recentSearches: slicedList,
      };
    }
    case SEARCH_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
