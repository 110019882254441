import { Dispatch } from 'redux';
import { animalListDataClear } from 'store/animals/actions';
import { communityListDataClear } from 'store/community/actions';
import { personDataClear } from 'store/person/actions';
import { searchClearHistory } from 'store/search/actions';
import { shelterDataClear } from 'store/shelter/actions';
import { usergroupsListClear } from 'store/usergroups/actions';
import { usernameClearLastUsed } from 'store/username/actions';

import { authDataClear } from '../auth/actions';

export const logout =
  () =>
  (dispatch: Dispatch): void => {
    dispatch<any>(authDataClear());
    dispatch<any>(personDataClear());
    dispatch<any>(shelterDataClear());
    dispatch<any>(usergroupsListClear());
    dispatch<any>(searchClearHistory());
    dispatch<any>(animalListDataClear());
    dispatch<any>(communityListDataClear());
    dispatch<any>(animalListDataClear());
  };

export const clearData =
  () =>
  (dispatch: Dispatch): void => {
    dispatch<any>(authDataClear());
    dispatch<any>(personDataClear());
    dispatch<any>(shelterDataClear());
    dispatch<any>(usernameClearLastUsed());
    dispatch<any>(usergroupsListClear());
    dispatch<any>(searchClearHistory());
    dispatch<any>(animalListDataClear());
    dispatch<any>(communityListDataClear());
    dispatch<any>(animalListDataClear());
  };
