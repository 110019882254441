import { CreatePerson, CreateShelter, makeLoggers } from '@colensobbdo/shelter-management-frontend-integration';
import _findIndex from 'lodash/findIndex';
import _first from 'lodash/first';
import _isArray from 'lodash/isArray';
import _last from 'lodash/last';
import _size from 'lodash/size';
import _slice from 'lodash/slice';
import _tail from 'lodash/tail';
import { RootState } from 'store/store';

import { RegistrationStepEnum } from './types';

const { fork } = makeLoggers('redux :: registration', false);

export const getCurrentRegistrationStep = (state: RootState): RegistrationStepEnum => state.registration.step;
export const isCreateNewShelterFlow = (state: RootState): boolean => state.registration.isCreateNewShelterFlow;
export const isInviteUserFlow = (state: RootState): boolean => state.registration.isInviteUserFlow;
export const isNewUser = (state: RootState): boolean => state.registration.isNewUser;
export const isVolunteer = (state: RootState): boolean => state.registration.isVolunteer;
export const isFoster = (state: RootState): boolean => state.registration.isFoster;
export const isVet = (state: RootState): boolean => state.registration.isVet;
export const getRegistrationDetails = (
  state: RootState,
): {
  person: CreatePerson.ValidateRequest | null;
  shelter: CreateShelter.ValidateRequest | null;
  optInAllUpdates: boolean;
  optInMarketingMyHooman: boolean;
  optInMarketingMarsAndAffiliates: boolean;
  optInForAnalytics: boolean;
} => ({
  person: state.registration.user,
  shelter: state.registration.shelter,
  optInAllUpdates: state.registration.optInAllUpdates,
  optInMarketingMyHooman: state.registration.optInMarketingMyHooman,
  optInMarketingMarsAndAffiliates: state.registration.optInMarketingMarsAndAffiliates,
  optInForAnalytics: state.registration.optInForAnalytics,
});

type ConditionalRegistrationStep = [(state: RootState) => boolean, RegistrationStepEnum];

type RegistrationFlowStep = RegistrationStepEnum | ConditionalRegistrationStep;

const SHELTER_MASTER_REGISTRATION_FLOW: RegistrationFlowStep[] = [
  'CreatePersonStep',
  'CreateShelterStep',
  'PrivacyPolicyStep',
  'TermsAndConditionsStep',
  'AnalyticsStep',
  'AllUpdatesStep',
  'MarketingStep',
  'CompleteRegistrationStep',
  'ActivationRequestConfirmation',
  'UpdatePersonStep',
  'CompleteShelterStep',
  'ConfirmationStep',
];

const COMMUNITY_USER_REGISTRATION_FLOW: RegistrationFlowStep[] = [
  [isNewUser, 'SetupFirstTimeProfileStep'],
  [isVolunteer, 'SetupVolunteerProfileStep'],
  [isFoster, 'SetupFosterProfileStep'],
  [isFoster, 'SetupFosterTagsStep'],
  [isVet, 'SetupVetProfileStep'],
  'PrivacyPolicyStep',
  'TermsAndConditionsStep',
  'AnalyticsStep',
  'AllUpdatesStep',
  'MarketingStep',
  'ProfileCreatedStep',
];

const CREATE_NEW_SHELTER_REGISTRATION_FLOW: RegistrationFlowStep[] = [
  'CreateShelterStep',
  'CompleteShelterStep',
  'ConfirmationStep',
];

export function getNextRegistrationStep(state: RootState): RegistrationStepEnum {
  const { log, logw } = fork('getNextRegistrationStep');

  const currentStep = getCurrentRegistrationStep(state);
  const createNewShelterFlow = isCreateNewShelterFlow(state);
  const inviteUserFlow = isInviteUserFlow(state);
  log('start', { currentStep, inviteUserFlow });

  // we support two registration flow depending if you're coming from the registration page or from the community invite email.
  const allSteps = createNewShelterFlow
    ? CREATE_NEW_SHELTER_REGISTRATION_FLOW
    : inviteUserFlow
    ? COMMUNITY_USER_REGISTRATION_FLOW
    : SHELTER_MASTER_REGISTRATION_FLOW;

  // find all the remaining steps based on the current one (stored within redux state)
  const currentStepIndex = _findIndex(allSteps, (step: RegistrationFlowStep) => {
    if (_isArray(step)) {
      return step[1] === currentStep;
    } else {
      return step === currentStep;
    }
  });

  log('currentStepIndex', currentStepIndex);

  let remainingSteps = _slice(allSteps, currentStepIndex + 1);

  // as some steps have a condition, like SetupVetProfileStep will be shown only if the user is a vet
  // we need to cycle and eventually test the current state to determine whenever we should proceed to the next step
  while (_size(remainingSteps)) {
    log('remainingSteps', remainingSteps);

    const step = _first(remainingSteps);

    if (!step) break;

    if (!_isArray(step)) {
      log('resolved', step);
      return step;
    }

    if (step[0](state)) {
      log('resolved', step);
      return step[1];
    }

    remainingSteps = _tail(remainingSteps);
  }

  // fallback
  logw('fallback', _last(allSteps));
  return _last(allSteps) as RegistrationStepEnum;
}
