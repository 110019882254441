export default {
  pages: {
    dashboard: '/dashboard',
    animals: {
      root: '/animals',
      register: '/animals/register',
      profile: (animalId?: string | number | null): string => `/animals/profile/${animalId || ':animalId'}`,
      application: {
        root: '/animals/applications',
        byStatus: (status?: string): string => `/animals/applications/status/${status || ':status'}`,
        byAnimal: (animalId?: string | number | null): string =>
          `/animals/applications/animal/${animalId || ':animalId'}`,
        byPerson: (personId?: string | number | null): string =>
          `/animals/applications/person/${personId || ':personId'}`,
        byPersonAndStatus: (personId?: string | number | null, status?: string): string =>
          `/animals/applications/person/${personId || ':personId'}/status/${status || ':status'}`,
        byId: (applicationId?: string | number | null): string =>
          `/animals/application/${applicationId || ':applicationId'}`,
        forced: (applicationId?: string | number | null): string =>
          `/animals/applications/forced/${applicationId || ':applicationId'}`,
        byAnimalAndStatus: (animalId?: string | number | null, status?: string): string =>
          `/animals/applications/animal/${animalId || ':animalId'}/status/${status || ':status'}`,
      },
      litter: (litterId?: string | number | null): string => `/animals/litter/${litterId || ':litterId'}`,
      activityLog: (animalId?: string | number | null): string =>
        `/animals/profile/${animalId || ':animalId'}/activity-log`,
      report: '/animals/report',
    },
    community: {
      root: '/community',
      user: (personId?: string | number | null): string => `/community/user/${personId || ':personId'}`,
      archived: '/community/archived',
    },
    faqs: {
      root: '/faqs',
    },
    summary: {
      root: '/summary/report',
    },
    forgotPassword: '/forgot-password',
    landingPage: '/landing-page',
    login: '/login',
    logout: '/logout',
    privacyPolicy: '/privacy-policy',
    adoptersPrivacyPolicy: 'https://myhooman.com/terms-and-conditions',
    registration: {
      root: '/registration',
      confirm: '/registration/confirm',
      acceptInvite: '/register/validate-email',
    },
    resendActivationEmail: '/resend-activation-email',
    resetPassword: '/reset-password',
    root: '/',
    search: {
      root: '/search',
      animals: '/search/animals',
      community: '/search/community',
      applications: '/search/applications',
    },
    settings: {
      account: '/settings/account',
      profile: '/settings/profile',
      shelter: '/settings/shelter',
      notificationsAndPrivacy: '/settings/notifications-and-privacy',
      marketing: '/settings/marketing',
      applications: {
        root: '/settings/applications',
        question: '/settings/applications/questions',
        statusEmail: '/settings/applications/status-email',
        customTerms: '/settings/applications/custom-terms-and-conditions',
      },
      community: '/settings/community',
      createNewShelter: '/settings/new-shelter',
    },
    tasks: {
      root: '/tasks',
    },
    termsAndConditionsPage: '/terms-and-conditions',
    unauthorized: '/unauthorized',
  },
};
