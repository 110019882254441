import { ScopeType } from '@colensobbdo/shelter-management-frontend-integration';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import routes from '../configs/routes';
import UnauthorizedPage from '../pages/UnauthorizedPage/UnauthorizedPage';
import { hasPermission, isAuthenticated } from '../store/auth/selectors';

export function withAuthentication<T>(WrappedComponent: React.ComponentType<T>, scope?: ScopeType): React.FC<T> {
  return function Authentication(props: T) {
    const isLoggedIn = useSelector(isAuthenticated);
    const hasAccess = useSelector(hasPermission(scope));

    if (!isLoggedIn) return <Redirect to={routes.pages.login} />;

    if (!hasAccess) return <UnauthorizedPage />;

    return <WrappedComponent {...props} />;
  };
}

export default withAuthentication;
