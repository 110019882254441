import {
  AccessLevelType,
  IAnimalDetails,
  ProfileType,
  ScopeType,
} from '@colensobbdo/shelter-management-frontend-integration';
import _indexOf from 'lodash/indexOf';
import moment from 'moment';

import { getAccountIsActive } from '../person/selectors';
import { RootState } from '../store';

export const getAccessToken = (state: RootState): string | null => {
  const { accessToken, accessTokenExpires } = state.auth || {};

  if (!accessToken) return null;

  if (!!accessTokenExpires && moment(accessTokenExpires).isBefore()) return null;

  return accessToken;
};

export const getRefreshToken = (state: RootState): string | null => {
  const { refreshToken, refreshTokenExpires } = state.auth || {};

  if (!refreshToken) return null;

  if (refreshTokenExpires && moment(refreshTokenExpires).isBefore()) return null;

  return refreshToken;
};

export const isAuthenticated = (state: RootState): boolean => !!getRefreshToken(state) && getAccountIsActive(state);

export const getAccessLevel = (state: RootState): AccessLevelType => state.auth.accessLevel;

export const hasAccessLevel4 = (state: RootState): boolean => getAccessLevel(state) <= AccessLevelType.Level4;
export const hasAccessLevel3 = (state: RootState): boolean => getAccessLevel(state) <= AccessLevelType.Level3;
export const hasAccessLevel2 = (state: RootState): boolean => getAccessLevel(state) <= AccessLevelType.Level2;
export const hasAccessLevel1 = (state: RootState): boolean => getAccessLevel(state) <= AccessLevelType.Level1;

export const getAccessScope = (state: RootState): string[] => state.auth.scope;
export const hasPermission =
  (scope: ScopeType | undefined) =>
  (state: RootState): boolean => {
    return scope ? _indexOf(getAccessScope(state), scope) !== -1 : true;
  };

export const getProfileTypes = (state: RootState): ProfileType[] => state.auth.profileTypes;

export const hasProfileType =
  (role: ProfileType | undefined) =>
  (state: RootState): boolean =>
    role ? state.auth.profileTypes?.includes(role) : false;

export const hasOnlyProfileType =
  (role: ProfileType | undefined) =>
  (state: RootState): boolean =>
    role ? state.auth.profileTypes?.includes(role) && state.auth.profileTypes?.length === 1 : false;

export const hasProfileTypeShelterMaster = (state: RootState): boolean =>
  state.auth.profileTypes?.includes(ProfileType.Master);
export const hasProfileTypeShelterAdmin = (state: RootState): boolean =>
  state.auth.profileTypes?.includes(ProfileType.Admin);
export const hasProfileTypeShelterStaff = (state: RootState): boolean =>
  state.auth.profileTypes?.includes(ProfileType.Staff);
export const hasProfileTypeVolunteer = (state: RootState): boolean =>
  state.auth.profileTypes?.includes(ProfileType.Volunteer);
export const hasProfileTypeFoster = (state: RootState): boolean =>
  state.auth.profileTypes?.includes(ProfileType.Foster);
export const hasProfileTypeVet = (state: RootState): boolean => state.auth.profileTypes?.includes(ProfileType.VET);

export const getIsAnimalInMyCare =
  (animal: IAnimalDetails | undefined) =>
  (state: RootState): boolean => {
    if (hasProfileTypeFoster(state) && animal?.foster?.personId === state.person.personId) return true;

    // @TODO if needed, need vetPracticeId associate with person
    // if (hasProfileTypeVet(state) && (animal?.practice?.practiceId === state.person.vetPracticeId???))  return true
    return false;
  };
