import { AccessLevelType, ProfileType, Scope } from '@colensobbdo/shelter-management-frontend-integration';

import { AUTH_DATA_CLEAR, AUTH_DATA_UPDATE, AuthActionTypes, AuthState } from './types';

const initialState: AuthState = {
  scope: [
    // If the user has no scope he can still see the accounts settings page
    // we need to manually handle this case
    Scope.Personal.SettingsView,
  ],
  accessLevel: AccessLevelType.Level5,
  profileTypes: [] as ProfileType[],
};

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case AUTH_DATA_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_DATA_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
