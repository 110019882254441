import {
  AnimalSpecieCategoryId,
  IShelterMode,
  ShelterIdType,
} from '@colensobbdo/shelter-management-frontend-integration';

export interface ShelterState {
  shelterId: ShelterIdType | null;
  shelterExternalId: string | null;
  name: string | null;
  email: string | null;
  photoUrl: string | null;
  enabledSpecies: AnimalSpecieCategoryId[];
  mode: IShelterMode;
}

export const SHELTER_DATA_UPDATE = 'SHELTER_DATA_UPDATE';
export const SHELTER_DATA_CLEAR = 'SHELTER_DATA_CLEAR';

export type ShelterDataUpdatePayload = Partial<ShelterState>;

interface ShelterDataUpdateAction {
  type: typeof SHELTER_DATA_UPDATE;
  payload: ShelterDataUpdatePayload;
}

interface ShelterDataClearAction {
  type: typeof SHELTER_DATA_CLEAR;
}

export type ShelterActionTypes = ShelterDataUpdateAction | ShelterDataClearAction;
