import {
  AccessLevelType,
  IAuthTokens,
  Scope,
  ensureInt,
  makeLoggers,
} from '@colensobbdo/shelter-management-frontend-integration';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import Jwt from '../../utilities/Jwt';
import { logout } from '../logout/actions';
import { personDataUpdate } from '../person/actions';
import { PersonState } from '../person/types';
import { shelterDataUpdate } from '../shelter/actions';
import { ShelterState } from '../shelter/types';
import { RootState } from '../store';
import { authDataUpdate } from './actions';
import { AuthState } from './types';

const { fork } = makeLoggers('state :: thunk :: auth', false);

/*
 * This will decode the authToken payload and extract
 * some user/shelter information to store within redux state.
 *
 */
export const thunkSaveTokens =
  (tokens: IAuthTokens | null): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const { log, logi } = fork('saveTokens');

    log('start', { tokens });

    if (!tokens) {
      dispatch(logout());

      return;
    }

    const decoded = Jwt.decode(tokens.accessToken);
    log('authToken payload', decoded);

    const getProfileTypes = (groups: string | string[] | undefined) => {
      if (!groups) return [];
      return typeof groups === 'string' ? [parseInt(groups)] : _map(groups, (id) => parseInt(id));
    };

    const newCredentials: Partial<AuthState> = {
      ...tokens,
      scope: _get(decoded, 'Permission', [
        // If the user has no scope they can still see the accounts settings page
        // we need to manually handle this case
        Scope.Personal.SettingsView,
      ]),
      profileTypes: getProfileTypes(_get(decoded, 'UserGroups', [])),
    };

    const newUser: Partial<PersonState> = {};
    const newShelter: Partial<ShelterState> = {};

    const accessLevel = ensureInt(_get(decoded, 'AccessLevel'), AccessLevelType.Level5);
    log('storing accessLevel', accessLevel);
    newCredentials.accessLevel = accessLevel as AccessLevelType;

    let countryId = _get(decoded, 'CountryID');
    if (countryId) {
      countryId = ensureInt(countryId);

      log('storing countryId', countryId);
      newUser.countryId = countryId;
    }

    let personId = _get(decoded, 'PersonID');
    if (personId) {
      personId = ensureInt(personId);

      log('storing personId', personId);
      newUser.personId = personId;
    }

    let shelterPersonId = _get(decoded, 'ShelterPersonID');
    if (shelterPersonId) {
      shelterPersonId = ensureInt(shelterPersonId);

      log('storing shelterPersonId', shelterPersonId);
      newUser.shelterPersonId = shelterPersonId;
    }

    let shelterId = _get(decoded, 'ShelterID');
    if (shelterId) {
      shelterId = ensureInt(shelterId);

      log('storing shelterId', shelterId);
      newShelter.shelterId = shelterId;
    }

    const email = _get(decoded, 'Email');
    if (email) {
      log('storing email', email);

      newUser.email = email;
    }

    log('storing new credentials', newCredentials);
    dispatch(authDataUpdate(newCredentials));

    if (!_isEmpty(newUser)) {
      logi('storing new user details', newUser);
      dispatch(personDataUpdate(newUser));
    }

    if (!_isEmpty(newShelter)) {
      logi('storing new shelter details', newShelter);
      dispatch(shelterDataUpdate(newShelter));
    }
  };
