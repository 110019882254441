import { AccessLevelType, IAuthTokens, ProfileType } from '@colensobbdo/shelter-management-frontend-integration';

// state

export type AuthState = Partial<IAuthTokens> & {
  scope: string[];
  accessLevel: AccessLevelType;
  profileTypes: ProfileType[];
};

// action

export const AUTH_DATA_UPDATE = 'AUTH_DATA_UPDATE';
export const AUTH_DATA_CLEAR = 'AUTH_DATA_CLEAR';

export type AuthDataUpdatePayload = Omit<AuthState, 'scope' | 'accessLevel' | 'profileTypes'> | AuthState;

interface AuthDataUpdateAction {
  type: typeof AUTH_DATA_UPDATE;
  payload: AuthDataUpdatePayload;
}

interface AuthDataClearAction {
  type: typeof AUTH_DATA_CLEAR;
}

export type AuthActionTypes = AuthDataUpdateAction | AuthDataClearAction;
